
/*
    JS file structure to follow:
    - Global variables
    - Initialization
    - Events
    - Callback functions
    - General functions

    IMPORTANT! : Initializations and events are to be placed in the $(function() { ... });
    IMPORTANT! : Callback functions and general functions are to be placed outside of the $(function() { ... });

    Don't forget to comment the code in english for better understanding and maintainability of the code. And if possible use explicit variables and function names.
*/

let isLazyLoad = is_lazy_load ? false : true;
let rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
let rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
let rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");
let userWishlist = [];

// Shows button's underlying loader and triggers defined action
let elmt;
let loader;
let prev_display;

let bloc_prod_xhr;

// FP main visu slider
let intervalId = null;
let spacer = 80;

let mainSlideFp = $(".mainSlideFp");
let thumbSlideFp = $(".thumbSlideFp");
let headerAndPreHeaderHeight = 0;

let addToCartTmpl;

$(document).ready(function () {
    const osInstance = OverlayScrollbars(document.body, {
        scrollbars: {
            visibility: 'auto', // Options: 'visible', 'hidden', 'auto'
            autoHide: 'move', // Options: 'never', 'scroll', 'leave', 'move'
            theme: 'os-theme-dark' // Use predefined themes or custom themes
        }
    });
});


let isStickyApplied = false; // Suivi de l'état sticky

// Store timeout IDs, use in : clearAllTimeouts, addTimeout
// use to have a debounce effect on hover events in menu
let timeouts = {
    general: [],
    subCategDownArea: [],
    cartHover: [],
    accountHover: [],
    birthlistHover: []
};

$(function () {
    $("#appContainer").on("click", ".prodSize", function () {
        var is_regroup = $(this).data('regroup');
        if (is_regroup === undefined) {
            is_regroup = false;
        }
        onItemSizeChange.call(this, this.form, is_regroup, $(this).data('rrid'));
    });
    eventButton();
    initBirthList();

    initFpSliders();
    $(window).on("resize", initFpSliders);
    $(window).on('resize', subcatMinWidth());

    backToTopShow();

    if ($('#appContainer').length) {
        $("#appContainer").on("mouseenter", ".item_product.one_size, .alt_sld_view.one_size", function () {
            if (!$(this).hasClass('is_clicked')) {
                $(this).addClass('is_clicked');
                $(this).find('.productSizeFieldset .prodSize').click();
            }
        });
    }

    updateCartElements();
    cms_look_popup();

    moveProductOutOfStock();
    wording_faq_cart();

    subcatMinWidth();

    $(window).scroll(function() {
        backToTopShow();
        headerContainer_sticky();
    });

    $('.back_to_top').on('click', function (e) {
        backToTopShow();
    });

    $(document).on("click", ".toggleCarac", function () {
        $(this).toggleClass('actif');
        var nextContent = $(this).next('.content');

        nextContent.slideToggle();

        if ($(this).hasClass('actif')) {
            nextContent.addClass('actif');
        } else {
            nextContent.removeClass('actif');
        }

        var detailLabel = $(this).attr("data-detail-label");

        if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
            if (detailLabel !== undefined && detailLabel !== '') {

                TriggerProductDetailClick(detailLabel);
            }
        }
    });

    // Preheader
    window.setTimeout(function () {
        if ($(".promo_swiper").length) {
            $("body").addClass("hasBando");
        }

        if ($("#openOrderGiftCard").length) {
            $("#lightboxOrderGiftCard").appendTo("#openOrderGiftCard");
            $("#openOrderGiftCard").removeAttr("onclick");
            $(".pageGiftCard__header").appendTo(".pageGiftCard__main--infos").addClass('visible');
            $(".pageGiftCard__main--amountinfos").appendTo(".pageGiftCard__header");
        }

        $('#ctaAddToGiftList.inactiv').mouseenter(function() {
            $('.disabledBtnTooltip').addClass('blink');
        });

        $('#ctaAddToGiftList.inactiv').mouseleave(function() {
            $('.disabledBtnTooltip').removeClass('blink');
        });
    }, 1000);

    // Listen to the wishToBasket_complete event to update the wishlist
    $(window).on("wishToBasket_complete", function () {
        document.location.href = create_link('order_basket');
    });

    // Remove product from wishlist header rollover
    $(document).on("click touch", ".remove_top_wishlist", function (ev) {

        var wishlist_product_id = $(this).attr("data-id");
        var product_id = $(this).attr("data-productid");
        var current_elem = $(this);

        $.post(path_relative_root + 'ajax_remove_from_wishlist.php', {id: wishlist_product_id}, function (data) {

            var nr;
            if (data.result == undefined) {
                data.result = $('.wish_liste_product .product_ctn').length;

            } else {
                nr = data.result.wishlistLength;
            }

            if (nr == 0) {
                $("#wishlist_top").removeClass('hasItem');
                $(".item.wishlist").hide();
            }

            // Update products in rayon
            if ($('a[data-productid = "' + product_id + '"]').length > 0) {
                $('a[data-productid = "' + product_id + '"]').removeClass("existToWishlistButton");
                $('a[data-productid = "' + product_id + '"]').data('wishlistproductid', '');
                $('a[data-productid = "' + product_id + '"]').attr('data-wishlistproductid', '');
            }

            var response_html = ajax_file(path_relative_root + 'ajax_show_wishlist.php');
            $("#wishlist_top").html(response_html);

            $(current_elem.parents('.product-link')).fadeOut(100, function () {

                current_elem.parents('.product-link').remove();
            });
        });
    });

    // Init price range filter
    document.onload = function () {
        if ($("#slider-range").length) {
            var prix_min = $("#amount1").val();
            var prix_max = $("#amount2").val();

            $("#amount1").val(prix_min) + siteCurrency.symbol_left;
            $("#amount2").val(prix_max) + siteCurrency.symbol_left;
            $("#slider-range").slider("option", {
                min: parseInt(prix_min),
                max: parseInt(prix_max),
                values: [parseInt(prix_min), parseInt(prix_max)],
            });

            $(".ui-slider-range").width("100%");
            $(".ui-slider-range").css("left", "0%");
            $(".ui-slider-range")
                .next()
                .css("left", "0%");
            $(".ui-slider-range")
                .next()
                .next()
                .css("left", "100%");
        }
    };

    /* Load global product swiper initialization on window scroll */
    if ($("#list_item .item_product").length) {
        initItemSlider();
        $(window).on('scroll', function () {
            initItemSlider();
        });
    }

    /* init product swiper on document ready */

    // Redirect to product
    if (getCookie('p_id') && $('#list_item .item').length) {
        setTimeout(() => {
            // Smooth scroll to the target element
            var curentProductId = getCookie('p_id');
            targetElement = $('[data-prod="' + curentProductId + '"]');

            if (targetElement.length) {
                var headerHeight = $("#site_head_wrap").outerHeight(); // Adjust selector as needed for the header
                var targetPosition = $(targetElement).offset().top - (headerHeight + 20); // Calculate target position

                // Remove the anchor from the URL after scrolling
                history.replaceState(null, null, ' '); // This removes the hash

                $('html, body').animate({
                    scrollTop: targetPosition
                }, 300); // Duration of the scroll

                eraseCookie('p_id');
            }
        }, 500);
    }

    $('body').on('click', '.toggle_modal_actions', function() {
        $(this).fadeOut('fast');
        $(this).parents('.product_line_basket').find('.cart_item_actions').slideDown('slows');
    });

    // Close lightbox when clicking outside of the lightbox
    $('.product_page .shad').click(function () {
        $('#lightboxGlAddToList').removeClass('actif');
        setTimeout(function () {
            closeMultiShad("lightboxAchatExpressV1");
            $("#bloc_add_alert_aev1").hide();
            $("#bloc_add_basket_aev1").addClass('disabled');
        }, 800);
    });

    // When the birth registry page loads, the tag is open but does not have the active class
    $('body.gift_list_show #gl_products_div .desc_pannel .toggleCarac').each(function() {
        if ($(this).hasClass("actif")) {
            $(this).next(".content").addClass("actif");
        }
    });

    // Close lightbox when clicking outside of the participate in a list lightbox
    $('.gift_list_show .shad').click(function () {
        $('.lightbox').removeClass('actif');
        setTimeout(function () {
            $('.shad').removeClass('actif');
        }, 800);
    });

    $('#detail_fav').on('click', function () {
        $(this).toggleClass('actif');
        $('.detail_mag').toggleClass('cache');
    });

    $('#formPanier #btn_cmd_valid span').click(function() {
        $(this).parent('#btn_cmd_valid').next('.cartLoader').addClass('loading');

        setTimeout(function () {
            $(this).parent('#btn_cmd_valid').next('.cartLoader').removeClass('loading');
        }, 3500);
    });

    $('.return_products_form').find('.holder_return_product').each(function () {
        var return_products_form_select_box_quantity = $(this).find('.return_quantity').val();
        var return_products_form_select_box_choice = $(this).find('.return_choice').attr('id');

        if (return_products_form_select_box_quantity === "0") {
            $('#' + return_products_form_select_box_choice).prop("disabled", true);
        } else {
            $('#' + return_products_form_select_box_choice).prop("disabled", false);
        }
    });

    $(".close_promo").on("click", function() {
        $('body').removeClass('hasBando promo_on pheader');
    });

    $('#sizeguideBox').on('mouseover touchstart', '.switch_gdt_btn', function () {
        if ($('.switch_gdt_btn').length) {
            var trigger_filtre_swiper = new Swiper('.switch_gdt_btn', {
                slidesPerView: 'auto',
                spaceBetween: 0,
            });
        }
    });

    tgModuleInit();

    $('.productSwiper:not(.swiper-initialized):not(.product_swiper_new)').on('mouseenter touchstart', function () {
        if ($(this).find('.rollover_rayonsNext').length && $(this).find('.rollover_rayonsPrev').length) {
            var this_productSwiper_button_next = $(this).find('.rollover_rayonsNext').attr('class').split(' ')[0];
            var this_productSwiper_button_prev = $(this).find('.rollover_rayonsPrev').attr('class').split(' ')[0];

            new Swiper(this, {
                slidesPerView: 1,
                navigation: {
                    nextEl: "." + this_productSwiper_button_next,
                    prevEl: "." + this_productSwiper_button_prev,
                },
            });
        }
    });

    $('#lightboxAchatExpressV1Pictures').on('mouseenter touchstart', function () {
        var this_product_swiper_new_button_next = $(this).find('.rollover_rayonsNext').attr('class');
        var this_product_swiper_new_button_prev = $(this).find('.rollover_rayonsPrev').attr('class');

        if ($(this).find(".swiper-container:not(.swiper-initialized) .swiper-slide").length > 1 ) {
            new Swiper('#lightboxAchatExpressV1Pictures .product_swiper_new', {
                slidesPerView: 1,
                navigation: {
                    nextEl: "." + this_product_swiper_new_button_next,
                    prevEl: "." + this_product_swiper_new_button_prev,
                },
            });
        }
    });

    $('.trigger_filtre_swiper').on('mouseenter touchstart', function () {
        if ($('.trigger_filtre_swiper').length) {
            var trigger_filtre_swiper = new Swiper('.trigger_filtre_swiper', {
                slidesPerView: 'auto',
            });
        }
    });

    $('#selection-offer').on('mouseenter touchstart', function () {
        if ($('.color_list').length) {
            new Swiper('.color_list', {
                slidesPerView: 'auto',
            });
        }

        if ($('.size_list .swiper-slide').length > 1) {
            new Swiper('.size_list', {
                slidesPerView: 'auto',
            });

            $('.size_list .swiper-slide').removeClass('swiper-no-swiping');
        }
    });

    $("#trigger_recherche").on("click touch", function () {
        if($(".wrap_search_field:visible").length) {
            closeSearch();
        } else {
            $(".wrap_search_field").fadeIn();
            $("body").addClass("search");
            $("#shad_menu").addClass("actif");
        }
    });

    $(".close_search").on("click", function () {
        let input_search = document.getElementById("search_home");

        if (input_search.value === "") {
            closeSearch();
        } else {
            input_search.value = "";
            input_search.focus();
        }
    });

    $(".wrap_search_field").on("mouseleave", function (event) {
        closeSearch();
    });

    $("#shad_menu").on("click", function () {
        $(".wrap_search_field").fadeOut();
        $("body").removeClass("search");
        $("#trigger_recherche").css("opacity", "1");
        $("#shad_menu").removeClass("actif");
    });

    /* Recherche bar on press enter button close search bar */
    $("#search_home").on("keypress", function (event) {
        if (event.which === 13) {
            closeSearch();
        }
    });

    $(document).on("click touch", ".mot_personnalise_form .w-checkbox-input p", function () {
        $("#mot_personnalise").slideToggle();
    });

    // Module look
    $(".home-module-body.look-module .tag_button, .cms-page-module-body.lookbook .tag_button").each(function () {
        var tag_button_id_cms = $(this).attr('data-id');

        $(this).on("click", function () {
            $('.product-tag > div').hide();
            $('#product-tag-' + tag_button_id_cms).show();
        });

    });

    $('.offerCardBtn').on('click', function () {
        $('.offerCardBtn').toggleClass('open');
        $('#lightboxOrderGiftCard').slideToggle();
    });

    $('#showAmount .showAmountBtn').on('click', function () {
        $(this).toggleClass('open');
    });

    //loader on tunnel payment page
    $('.wrapper_basket_content #selectAliasBtn').on('click', function() {
        $(this).next('.loader').addClass('loading');

        setTimeout(function () {
            $(this).next('.loader').removeClass('loading');
        }, 500);
    });

    //loader on tunnel thanks page
    $('.thanksWrapper .bankThanksBtn').on('click', function() {
        $(this).next('.loader').addClass('loading');

        setTimeout(function () {
            $(this).next('.loader').removeClass('loading');
        }, 500);
    });

    // Event handler for hover on the banner and menu items
    $('.headerRollover')
        .on('mouseenter', function () {
            clearAllTimeouts('general');
            toggleBannerClasses(true);
            addTimeout('general', () => {
                // On all pages except the category pages & the product page
                if (!$('body').hasClass('category') && !$('body').hasClass('product_page')) {
                    $('.subcateg').removeClass('show');
                }
            }, 500);
        })
        .on('mouseleave', function () {
            if(!$(".wrap_search_field:visible").length) {
                addTimeout('general', () => toggleBannerClasses(false), 700);
            }
        });

    // Event handler for hover on the super-categories and categories
    $('.supercateg_item, #site_head_wrap .categories, .subcategElement, .SsCategories, .categories .menu_button')
        .on('mouseenter', function () {
            clearAllTimeouts('general');
            toggleBannerClasses(true);
            const targetId = $(this).attr('id');
            const targetCateg = $('.categories').find(`[data-id='${targetId}']`);
            if ($(this).hasClass('supercateg_item')) {
                $('.supercateg_item').removeClass('show');
                $('.categories').addClass('show');
                $('.categories .swiper-container').not(targetCateg).removeClass('show');
                targetCateg.addClass('show');
                $(this).addClass('show');

                // Init categ slider on mouseenter
                setTimeout(function (){
                    initCategSwiper(targetCateg[0]);
                }, 200);
            }

            if ($(this).parents(targetCateg).hasClass('supercateg_item')) {
                initCategSwiper(targetCateg[0]);

            }
        })
        .on('mouseleave', function () {
            addTimeout('general', () => {
                if ($('.SsCategories').hasClass('show')) {
                    $('.SsCategories').removeClass('show');
                    $('.SsCategories').css('height', '0');
                }
                if(!$(".wrap_search_field:visible").length) {
                    toggleBannerClasses(false);
                }
                // On all pages except the category pages & the product page
                if (!$('body').hasClass('category') && !$('body').hasClass('product_page')) {
                    $('.supercateg_item, .subcateg, .ss_menu_categ_wrapper').removeClass('show');
                }
                toggleCategories();
            }, 500);
        });

    // Event handler for hover on the sub-categories
    $('.ss_menu_categ_wrapper, #site_head_wrap .categories')
        .on('mouseenter', function () {
            clearAllTimeouts('general');
            toggleBannerClasses(true);
            if ($(this).hasClass('ss_menu_categ_wrapper') || $(this).hasClass('menu_arrow')) {
                $('.ss_menu_categ_wrapper').removeClass('show');
                $(this).addClass('show');
                $('.SsCategories').addClass('show');
                $('.SsCategories').css('height', '310px');
                const targetId = $(this).attr('id');
                const targetScateg = $('.SsCategories').find(`[data-id='${targetId}']`);
                $('.SsCategories .wrap_push_sscat').not($(targetScateg)).removeClass('show');
                $(targetScateg).addClass('show');
            }
        })
        .on('mouseleave', function () {
            // Do nothing
        });

    // Event handler for hover on the sub-categories and sub-sub-categories
    $('.subcateg, .SsCategories')
        .on('mouseenter mouseover', function () {
            clearAllTimeouts('subCategDownArea');
        })
        .on('mouseleave', function () {
            addTimeout('subCategDownArea', () => {
                $('.ss_menu_categ_wrapper').removeClass('show');
                setTimeout(function () {
                    $('.SsCategories').removeClass('show');
                    $('.SsCategories').css('height', '0');
                    $('.SsCategories .wrap_push_sscat').removeClass('show');
                }, 0);
            }, 100);
        });

    // Event handler for hover to open the cart aside
    $('#cart_top, #show_top_cart')
        .on('mouseenter mouseover', function () {
            clearAllTimeouts('cartHover');
            if ($(this).is('#cart_top')) {
                $("#show_top_cart").addClass("open");
                $("#cart_top a").removeClass("cart");
                $("#cart_top a").addClass("cart_full");
                $("#shad_menu").addClass("actif");
            }
        })
        .on('mouseleave', function () {
            $("#show_top_cart").removeClass("open");
            $("#cart_top a").removeClass("cart_full");
            $("#cart_top a").addClass("cart");
            $("#shad_menu ").removeClass("actif");
        });

    // Event handler for hover to open the account menu
    $('#account_top, #topLogin')
        .on('mouseenter mouseover', function () {
            if ($(this).is('#account_top')) {
                $("#account_top .rollAccountEntries").addClass("open");
                $("#account_top a").removeClass("account");
                $("#account_top a").addClass("account_full");
                $("#shad_menu").addClass("actif");
            }
        })
        .on('mouseleave', function () {
            $("#account_top .rollAccountEntries").removeClass("open");
            $("#account_top a").removeClass("account_full");
            $("#account_top a").addClass("account");
            $("#shad_menu").removeClass("actif");
        });


    if ($('.subcateg.swiper-container.active').length) {
        var onPageLoadedSwiper = $('.subcateg.swiper-container.active');
        initCategSwiper(onPageLoadedSwiper[0]);
    }

    // Event handler for hover to open the birth list
    $('#birthlist_top, #giftlist_top')
        .on('mouseenter mouseover', function () {
            if ($(this).is('#birthlist_top')) {
                $("#birthlist_top .rollAccountEntries").addClass("open");
                $("#birthlist_top a").removeClass("birth");
                $("#birthlist_top a").addClass("birth_full");
                $("#shad_menu").addClass("actif");
            }
        })
        .on('mouseleave', function () {
            $("#birthlist_top .rollAccountEntries").removeClass("open");
            $("#birthlist_top a").removeClass("birth_full");
            $("#birthlist_top a").addClass("birth");
            $("#shad_menu").removeClass("actif");
        });

    $('.menu_itm_hover, #site_head_wrap .categories')
        .on('mouseenter mouseover', function(){
            if ($('.categories').hasClass('active')){
                $('.categories').removeClass('active');
            }
        })
        .on('mouseleave', function(){
            // Do Nothing
        });

    // LightBox page Magazine
    if ($(".bloc_magazine").length ) {
        $(".bloc_magazine .magazine_cta .bloc_magazine").each(function () {
            var magasin_id = $(this).parents('.magazine_cta').next('.lightbox_magazine').attr('id');
            $(this).on("click", function () {
                $(this).parents('.magazine_cta').next('.lightbox_magazine').css('display','flex').addClass('actif');
                openMultiShad(magasin_id);
            });

            $('.lightbox_magazine .close_pop').on("click", function () {
                $(this).parents('.lightbox_magazine').hide().removeClass('actif');
                closeMultiShad(magasin_id);
            });
        });
    }

    // Slider cover page location
    if ($(".slider_location_container").length ) {
        var tdg_nos_offre_slider_container = new Swiper(".slider_location_container", {
            slidesPerView: 1,
        });
    }

    if ($("#cookiesLightbox").length) {
        $("#js_cookie_refuse").insertAfter(".cookiesInfos");
    }

    $('.politique_confidentialite .content_sat .cookieShowLightbox').click(function() {
        $('.politique_confidentialite #cookiesLightbox').addClass('displayCookies');

        if ($('.politique_confidentialite #cookiesLightbox').has('.hideCookies')) {
            $('.politique_confidentialite #cookiesLightbox').removeClass('hideCookies').show();
        }
    });

    $('.politique_confidentialite #js_cookie_refuse, .politique_confidentialite #js_cookie_accept').click(function() {
        if ($('.politique_confidentialite #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.politique_confidentialite #cookiesLightbox').removeClass('displayCookies');
                $('.politique_confidentialite #cookiesLightbox').addClass('hideCookies').hide();
            }, 500);
        }
    });

    // Init swiper "tête de gondole" page location
    if ($(".tdg_nos_offre_slider_container").length ) {
        new Swiper(".tdg_nos_offre_slider_container", {
            loop: true,
            slidesPerView: 2,
            spaceBetween: 5,
            scrollbar: {
                el: ".tdg_nos_offre .swiper-pagination",
                type: "progressbar",
                draggable: true,
            },
            navigation: {
                nextEl: ".tdg_nos_offre .tete_de_gondole-button-next",
                prevEl: ".tdg_nos_offre .tete_de_gondole-button-prev",
            },
            breakpoints: {
                1120: {
                    slidesPerView: 3,
                },
                1210: {
                    slidesPerView: 4,
                },
            },
        });
    }

    // Page location
    if ($(".store_img .swiper-container .swiper-slide").length > 1 ) {
        var store_img_swiper = new Swiper(".store_img .swiper-container", {
            slidesPerView: 1,
            navigation: {
                nextEl: ".store_img_button.swiper-button-next",
                prevEl: ".store_img_button.swiper-button-prev",
            },
        });
    }

    // Sticky page location
    if ($(".sticky_location_bar").length ) {
        var sticky_location_bar = $(".sticky_location_bar");
        var slider_location = $('.slider_location');
        var sticky_location_barH = sticky_location_bar.height();
        var slider_locationH = slider_location.height();

        $(window).scroll(function() {
            if ( $(this).scrollTop() > slider_locationH ) {
                sticky_location_bar.fadeIn();
                $('body').css({'margin-bottom':sticky_location_barH});
            } else {
                sticky_location_bar.fadeOut();
                $('body').css('margin-bottom','0');
            }
        });
    }

    // FAQ page location
    if ($(".bloc_faq_location").length ) {
        $(".bloc_faq_location .mes_questions ").each(function () {
            $(this).on('click', function () {
                $(".mes_reponses").slideUp();
                if ($(this).hasClass('active')){
                    $(this).find('.mes_reponses').slideUp();
                    $(this).removeClass('active');
                } else {
                    $(this).addClass('active').find('.mes_reponses').slideDown();
                    $('.mes_questions').not(this).removeClass('active');
                }
            });
        });
    }

    // Module Home
    if ($(".home-module-body.slider-module .swiper-container .swiper-slide").length > 1 ) {
        var home_slider_module = new Swiper(".slider-module .swiper-container", {
            slidesPerView: 1,
            speed: 2000,
            loop: true,
            lazy: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            pagination: {
                el: ".slider-module .swiper-container .swiper-pagination",
                clickable: true,
            },
            on: {
                touchStart: function() {
                    $(".swiper-slide iframe").css("pointer-events", "none");
                },
                sliderMove: function() {
                    $(".swiper-slide iframe").css("pointer-events", "none");
                },
                touchEnd: function() {
                    $(".swiper-slide iframe").css("pointer-events", "auto");
                },
                slideChangeTransitionEnd: function() {
                    $(".swiper-slide iframe").css("pointer-events", "auto");
                }
            }
        });
    }

    if ($(".home-module-body.cover-module .swiper-container").length ) {
        var home_cover_module = new Swiper(".home-module-body.cover-module .swiper-container", {
            slidesPerView: 2,
            speed: 2000,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            pagination: {
                el: ".cover-module .swiper-container .swiper-pagination",
                clickable: true,
            },
        });
    }

    if ($(".home-module-body.image-image-image .swiper-container").length ) {
        var home_image_image_image_module = new Swiper(".home-module-body.image-image-image .swiper-container", {
            slidesPerView: 2.2,
            spaceBetween: 6,
            breakpoints: {
                1160: {
                  slidesPerView: 3,
                  spaceBetween: 6,
                },
            },
        });
    }

    if ($('.sub_categ_wrapper .swiper').length) {
        var trigger_filtre_rayon = new Swiper('.sub_categ_wrapper .swiper', {
            slidesPerView: 'auto',
            watchOverflow: true,
            navigation: {
                nextEl: ".bloc_sub_categ_arrow.swiper-button-next",
                prevEl: ".bloc_sub_categ_arrow.swiper-button-prev",
            },
        });
    }

    if ($('#hashtag_module_frame').length) {
        var trigger_filtre_rayon = new Swiper('#hashtag_module_frame', {
            slidesPerView: 2.8,
            spaceBetween: 5,

            breakpoints: {
                640: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                  loop: true,
                  slidesOffsetBefore: 158,
                },
                1160: {
                    slidesPerView: 4,
                    spaceBetween: 5,
                    centeredSlides: false,
                    loop: false,
                    slidesOffsetBefore: 0,
                },
                1450: {
                    slidesPerView: 6,
                    spaceBetween: 5,
                    centeredSlides: false,
                },
            },
        });

        function set_trigger_filtre_rayon_height() {
            var trigger_filtre_rayon_element_width = $('#hashtag_module_frame .swiper-slide').width();

            $('#hashtag_module_frame .swiper-slide').css('height', trigger_filtre_rayon_element_width + 'px');
        }

        set_trigger_filtre_rayon_height();

        $(window).on('resize', function() {
            set_trigger_filtre_rayon_height();
        });
    }

    // LightBox lookhome
    if ($(".productVisualMulti_look").length) {
        $(".home-module-body:not(.look-module) .tag_button").each(function () {
            if (!$(this).hasClass("actif")) {
                $(this).on("click", function () {
                    var this_wishlist_title_wrapper = $(this).parent('.tag').find('.wishlist_title_wrapper');
                    var this_wishlist_brand_wrapper = $(this).parent('.tag').find('.wishlist_brand_wrapper');
                    var this_data_id_item_product = $(this).parent('.tag').find('.item_product').attr('id');
                    var item_product_lightbox = $(this).next(".item_product");
                    var size_list_selected = false;
                    var size_not_selected_txt = $('<span class="no_size_selected">' + Translator.translate('choose_size_fp_disabled_btn') + '</span>');
                    var block_disabled_whislist = $('<div class="disabled_whislist"></div>');

                    $(".home-module-body:not(.look-module) .tag_button").fadeOut();

                    if ($(this).parent('.tag').find('.productVisualMulti_look .wrap_rolloverproduit').length ) {
                        $(this).parent('.tag').find('.productVisualMulti_look .wrap_rolloverproduit').appendTo(this_wishlist_title_wrapper);
                        $(this).parent('.tag').find('.wishlistBtn').appendTo(this_wishlist_brand_wrapper);
                    }

                    $(this).addClass('actif');
                    $(this).parents('.tag').find('.item_product').addClass('actif');

                    openMultiShad(this_data_id_item_product);

                    if ($(".bloc_look_wrapper .swiper_container_look .swiper-slide").length > 1) {
                        var home_swiper_look = new Swiper(".swiper_container_look", {
                            slidesPerView: 1,
                            navigation: {
                                nextEl: ".swiper-button-next",
                                prevEl: ".swiper-button-prev",
                            },
                        } );
                    }

                    item_product_lightbox.each(function() {
                        if ($(this).find(".productSizeFieldset .form_itm.size").length > 1 ) {
                            if ($(this).find(".prodSize").is(":checked")) {
                                size_list_selected = true;
                                return false;
                            }

                            if (!size_list_selected) {
                                item_product_lightbox.find(".bloc_add_color").addClass("inactiv");
                                item_product_lightbox.find(".productSizeFieldset").append(size_not_selected_txt.hide());
                                item_product_lightbox.find(".achat_express").append(block_disabled_whislist);
                                item_product_lightbox.find(".wrapper_description").addClass("inactiv");

                                item_product_lightbox.find(".disabled_whislist, .bloc_add_color")
                                    .on('mouseenter', function() {
                                        // Mouse enter
                                        item_product_lightbox.find(size_not_selected_txt).addClass("show");
                                    })
                                    .on('mouseleave', function() {
                                        // Mouse leave
                                        item_product_lightbox.find(size_not_selected_txt).removeClass("show");
                                    });
                            }
                        } else {
                            $(this).find(".prodSize").prop("checked",true);
                        }
                    });
                });
            }
        });

        $(".close_look_lightbox").on("click", function () {
            var this_data_id_item_product_close = $(this).parent('.tag').find('.item_product').attr('id');

            $(this).parents('.tag').find('.item_product').removeClass('actif');
            $(".tag").removeClass('actif');
            $(".tag_button").removeClass('actif');
            $(".home-module-body:not(.look-module) .tag_button").fadeIn();
            closeMultiShad(this_data_id_item_product_close);
        });
    }

    // Cart product associations
    if ($('#cartAssociations').length > 0) {

        var cartAssociations = new Swiper('#cartAssociations', {});
    }

    if ($('body.newsletter').length) {
        var text_newsletter = '<p class="text_newsletter">'+Translator.translate('text_newsletter')+'</p>';
        $('#subscriptionForm .w-form-line:nth-of-type(2)').prepend(text_newsletter);
    }

    if ($("body.giftcard_index").length) {
        $(".pageGiftCard section").wrapAll("<div class='new_section'></div>");
        $(".pageGiftCard").css("opacity", "1");
    }

    if ("body.order_cgv, politique_confidentialite") {
        var btnBackToTop = $(".back_top");

        btnBackToTop.click( function () {
            $("html,body").animate({
                scrollTop: 0
            }, "slow")
        });

        $('body').on("scroll touchmove", function () {
            var o = $('body').scrollTop();
            o > 150 ? btnBackToTop.addClass("show") : btnBackToTop.removeClass("show")
        });
    }

    // CATEGORIES APPEAR WHEN ON SELECTED SUBCATEG
    if ($('body.category').length || $('body.product_page').length) {
        $('.current').parents('#wrapper_top_menu .categories').addClass('show active');
        $('.current.swiper-container').addClass('show active');
        $('.current').parents('#wrapper_top_menu .menu_itm_hover').addClass('current');
        $('.supercateg_item.current').addClass('show');
    }

    // Category SEO txt read more
    if ($('.txt.trimed').length) {

        var trimedTxt = $('.txt.trimed');
        var fullTxt = $('.txt.full');
        var read_more = $('.read_more');
        var read_less = $('.read_less');

        read_more.on('click', function () {
            trimedTxt.addClass('cache');
            fullTxt.removeClass('cache');
        });

        read_less.on('click', function () {
            trimedTxt.removeClass('cache');
            fullTxt.addClass('cache');
        });
    }

    // CMS SWIPER
    if ($(".three-images .cms-page-columns-container .swiper-slide").length) {
        var cmsPageThreeImages = new Swiper(".three-images .cms-page-columns-container", {
            slidesPerView: 2.2,
            spaceBetween: 6,
            breakpoints: {
                1160: {
                  slidesPerView: 3,
                },
            },
        });
    }

    if ($(".slider-cover-module .swiper-slide").length > 1) {
        var cmsPageHead = new Swiper( ".slider-cover-module .swiper-container", {
            slidesPerView: 1,
            pagination: {
                el: ".slider-cover-module .swiper-container .swiper-pagination",
                clickable: true,
            },
            speed: 1500,
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            },
            autoplayDisableOnInteraction: false
        } );
    }

    if ($(".cms-page-module-body.push-2").length) {
        $('.blocs .align-left').on('click', function () {
            $('.blocs .align-left').toggleClass('show');
        })
    }

    if ($(".list_regroup_content .lightbox .swiper-container .swiper-slide").length > 1) {
        var slider_naissance = new Swiper(".list_regroup_content .lightbox .swiper-container", {
            slidesPerView: 1,
            navigation: {
                nextEl: '.swiper_container_buttons .swiper-button-next',
                prevEl: '.swiper_container_buttons .swiper-button-prev',
            },
        });
    }

    // PROMO BAR
    if ($(".promo_swiper .swiper-slide").length > 1) {
        promoSwiper = new Swiper(".promo_swiper", {
            centeredSlides: true,
            loop: true,
            slidesPerView: 'auto',
            speed: 1500,
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            },
            autoplayDisableOnInteraction: false
        });
    }

    if ($('body.store_locator').length) {
        setTimeout(function () {
            if ($("#list-store li").length > 2) {
                OverlayScrollbars(document.querySelector('#store-container'), {});
            }
        }, 250);
    }

    if ($('#keep_alive_coupon').length) {
        $('#keep_alive_coupon .w-input-label').on('click', function () {
            $(this).parents('#keep_alive_coupon').find('#formPromo').toggleClass('actif');
            $(this).parents('#keep_alive_coupon').find('#input_codePromo').slideToggle();
        });
    }

    if ($('#cart_total .bill_line.total_produit.under_dropdown').length) {
        $('#cart_total .bill_line.total_produit.under_dropdown label').html(Translator.translate('sous_total'));
    }

    if ($('body.step_1 #cart_total .bill_line.total_produit').length) {
        $('body.step_1 #cart_total .bill_line.total_produit label').html(Translator.translate('sous_total'));
    }

    if ($('body.cart.step_1').length) {
        $('<div class="title_gondole_wrapper"></div>').appendTo('#site_global_wrap');
    }

    if ($(".brand_container_swiper .swiper-slide").length > 5) {
        brand_container_swiper = new Swiper(".brand_container_swiper", {
            slidesPerView: 5,
            spaceBetween: 5,
        });
    }

    if ($(".productSwiper_visited .swiper-slide").length > 1) {
        $('body').on('mouseenter touchstart', '.productSwiper_visited:not(.swiper-initialized)', function () {
            var this_productSwiper_visited_button_next = $(this).find('.rollover_rayonsNext').attr('class');
            var this_productSwiper_visited_button_prev = $(this).find('.rollover_rayonsPrev').attr('class');

            var productSwiper_visited_x = new Swiper('.productSwiper_visited:not(.swiper-initialized)', {
                slidesPerView: 1,
                navigation: {
                    nextEl: "." + this_productSwiper_visited_button_next,
                    prevEl: "." + this_productSwiper_visited_button_prev,
                },
            });
        });
    }

    if ($('body').hasClass('category')) {
        if ($(".banner_brand_img").length) {
            $('body').addClass('has_banner_brand_img');
        }
    }

    if ($('body').hasClass('product_page')) {
        window.setTimeout(checkSubmitStateProduct, 100);
        window.setTimeout(eventSizeButton, 1000);
    }

    // Footer reassurance
    if (~window.location.href.indexOf("#paiement")) {
        window.setTimeout(function() {
            $('#theme_global #theme_4').click();
        }, 200);
    }

    if (~window.location.href.indexOf("#payment")) {
        window.setTimeout(function() {
            $('#theme_global #theme_4').click();
        }, 200);
    }

    if (~window.location.href.indexOf("#livraisons_et_retours")) {
        window.setTimeout(function() {
            $('#theme_global #theme_5').click();
        }, 200);
    }

    if (~window.location.href.indexOf("#deliveries_and_returns")) {
        window.setTimeout(function() {
            $('#theme_global #theme_5').click();
        }, 200);
    }

    if (~window.location.href.indexOf("#service_client")) {
        window.setTimeout(function() {
            $('#theme_global #theme_7').click();
        }, 200);
    }

    if (~window.location.href.indexOf("#customer_service")) {
        window.setTimeout(function() {
            $('#theme_global #theme_7').click();
        }, 200);
    }

    if (~window.location.href.indexOf("#retours_et_remboursement")) {
        window.setTimeout(function() {
            $('#theme_global #theme_6').click();
        }, 200);
    }

    if (~window.location.href.indexOf("#returns_and_refunds")) {
        window.setTimeout(function() {
            $('#theme_global #theme_6').click();
        }, 200);
    }

    if ($('#theme_global').length) {
        $('#copyright .reinsurance .paiement').on("click touch", function () {
            $('#theme_global #theme_4').click();
        });

        $('#copyright .reinsurance .livraisons_retours').on("click touch", function () {
            $('#theme_global #theme_5').click();
        });

        $('#copyright .reinsurance .contact').on("click touch", function () {
            $('#theme_global #theme_7').click();
        });

        $('#copyright .reinsurance .retours_remboursement').on("click touch", function () {
            $('#theme_global #theme_6').click();
        });

        $("#copyright .reinsurance a").click(function() {
            window.setTimeout(function() {
                $("html, body").delay(1000).animate({
                    scrollTop: $('#theme_global').offset().top-100
                }, 1500);
            }, 100);
        });
    }

    if ($('.bloc_add_alert_form').length) {
        $('.bloc_add_alert_form').each(function () {
            $(this).find('.w-input .w-input-element').keypress(function(e) {
                var key = e.which;
                var send_mail_alert_stock = $(this).parents('.wrap_rolloverproduit');

                if (key == 13) {
                  $(send_mail_alert_stock).find('.bloc_add_alert_form .form_submit button').click();
                  return false;
                }
            });
        });
    }

    if ($('#products_look').length) {
        $('.associated_product').each(function () {
            if ($(this).find('.associated_product_sizes select option').length > 1) {
                $(this).addClass('has_multiple_sizes');
            }
        });
    }

    if ($(".wish_liste_product").length) {
        $(".wish_liste_product .product_ctn").each(function () {
            $(this).find('.addToCartFromWishlist span').on('click', function () {
                $(this).closest('.addToCartFromWishlist').addClass('loading');
            });
        });
    }

    if ($('.fidelity_point .swiper-wrapper .swiper-slide').length > 3) {
        new Swiper('.swiper', {
            slidesPerView: 3,
            spaceBetween: 5,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    }

    if ($("body.cart.step_1").length && $(".push_container.pushBasketWrapper").length) {
        processPushBasket();
    } else {
        $('.wrap_bottom_rightcol').after($('.fidelity_program_bloc'));
        $('.fidelity_program_bloc, .pushBasketWrapper').fadeIn();
    }

    // Move the CTA below the thank you page block
    if ($("body.bankThanks").length) {
        $(".bankThanksBtn").css('opacity', 1);
    }

    // Wrap elements of div to put overlayScrollbar
    if ($("body.product_page #formGlAddToList .gl_details").length) {
        var $list_details = $('body.product_page #formGlAddToList .gl_details');
        $list_details.wrapAll('<div class="wrapped_details"></div>');
    }

    if ($(".product_option .info")) {
        $('.product_option .info')
            .on('mouseenter', function () {
                var lightbox = $(this).attr("data-lightbox");
                $('#' + lightbox).addClass('actif');
            })
            .on('mouseleave', function () {
                var lightbox = $(this).attr("data-lightbox");
                $('#' + lightbox).removeClass('actif');
            });
    }

    if ($('#site_global_wrap').length) {
        // transition au chargement
        $('#site_global_wrap').fadeIn(5000);
    }

    //Toggle product details in cart right column
    $(".total_produit").on("click", function () {
        $(this).toggleClass("open");
        $(".total_produit_dropdown").slideToggle();
    });
});

// Smoothly scrolls back to top
function backToTop() {
    var scrollTop = $(window).scrollTop();
    var speed = Math.min(scrollTop * 0.8, window.innerHeight);

    $('html, body').animate({ scrollTop : 0 }, (speed * 2));
}

var formatPrice = (function () {
    var THREEDIGITS = new RegExp("\\d{3}", "g");

    // Splits a number into chunks of 3 digits
    function splitThousands(nmbr) {
        var f_chunk = "",
            chunk_length;

        nmbr = String(nmbr);
        chunk_length = nmbr.length % 3;

        if (chunk_length) {
            f_chunk = nmbr.slice(0, chunk_length);
            nmbr = nmbr.substr(chunk_length);
        }

        return (
            f_chunk +
            nmbr.replace(THREEDIGITS, function (match) {
                return " " + match;
            })
        );
    }

    return function (price) {
        var FORMAT = window.price_format_plain;
        var CURRENCY = window.siteCurrency;
        var output;

        if (price.constructor !== String) {
            price = price.toString(10);
        }

        price = price.replace(".", ",");
        price = price.split(",");
        var decimal = (typeof price[1] !== "undefined" ? price[1] : '');
        var no_cents = (decimal === '0' || decimal === '00' || decimal === '');

        if (no_cents && typeof window.PRICE_FORMAT_PLAIN_JS_NO_CENTS != 'undefined' && window.PRICE_FORMAT_PLAIN_JS_NO_CENTS !== 'PRICE_FORMAT_PLAIN_JS_NO_CENTS') {
            FORMAT = window.PRICE_FORMAT_PLAIN_JS_NO_CENTS;
        }

        if (decimal.length === 1) {
            decimal += "0";
        }

        output = FORMAT.replace("[<unit>]", splitThousands(price[0]));
        output = output.replace("[<symbol_right>]", CURRENCY.symbol_right);
        output = output.replace("[<symbol_left>]", CURRENCY.symbol_left);
        output = output.replace("[<decimal>]", decimal);
        output = output.replace("[<code>]", CURRENCY.code);

        return output;
    };
})();

// search handle functions
function closeSearch() {
    $(".wrap_search_field").fadeOut();
    $("body").removeClass("search");
    $("#trigger_recherche").css("opacity", "1");
    $("#shad_menu").removeClass("actif");
}

function closeSearchRecap() {
    let input_search = document.getElementById("search_home");

    input_search.value = "";
    input_search.focus();
}

function open_search_form() {
    $("#trigger_recherche").click();
}

function triggerFunctionOnSearchEnd() {
    tgModuleInit();
    backToTop();
}
// end of search handle functions

// Function to clear all timeouts in a specific category
function clearAllTimeouts(category) {
    timeouts[category].forEach(clearTimeout);
    timeouts[category] = [];
}

// Function to set a timeout in a specific category and add to the list
function addTimeout(category, callback, delay) {
    const id = setTimeout(callback, delay);
    timeouts[category].push(id);
}

// Function to handle the display of "show" and "actif" classes on the banner
function toggleBannerClasses(show) {
    $(".headerContainer").toggleClass("show", show);
    $("#site_head_wrap").toggleClass("actif", show);
    $("#shad_menu").toggleClass("actif", show);
}

// Function to manage the state of categories and super-categories
function toggleCategories() {
    if ($('body.category').length || $('body.product_page').length) {
        $('#wrapper_top_menu .categories').removeClass('show active').has('.current').addClass('show active');
        $('#wrapper_top_menu .menu_itm_hover').has('.current').addClass('current');
        $('.supercateg_item.show').removeClass('show');
        $('.supercateg_item.current').addClass('show');
    }
}

// function to handle categories min-width according to supercategories
function subcatMinWidth() {
    min_width = $('.main_menu .superCategories').outerWidth();
    menu_padding_adjustment = 30;
    addjusted_width = min_width - menu_padding_adjustment;

    $('.main_menu .categories').css('min-width', addjusted_width + 'px');

}

function initBirthList() {
    $('#giftlist_top').addClass('rollAccountEntries');
    $('#giftlist_top').show();
}

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, '');
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, '');
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, '');
}

function stripCombo(str) {
    let output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

// Allows the non-refresh of a page by exploiting the XHR object
function ajax_file(fichier) {
    if (window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else
        return (false);
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return (xhr_object.responseText);
    else return (false);
}

function ajax_file_post(url, params) {
    if (window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

    xhr_object.open("POST", url, false);
    xhr_object.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr_object.send(params);

    if (xhr_object.readyState == 4 && xhr_object.status == 200) {
        return xhr_object.responseText;
    }
}

function ajax_file_XML(fichier) {
    if (window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else
        return (false);
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return (xhr_object.responseXML);
    else return (false);
}

// Create the instantiation of the XHR object that the browser imports
function getXMLHttpRequest() {
    let xhr = null;

    if (window.XMLHttpRequest || window.ActiveXObject) {
        if (window.ActiveXObject) {
            let tableau_xhr_activeX = [
                'Msxml2.XMLHTTP.6.0',
                'Msxml2.XMLHTTP.3.0',
                'Msxml2.XMLHTTP',
                'Microsoft.XMLHTTP'
            ];

            for (let value in tableau_xhr_activeX) {
                try {
                    xhr = new ActiveXObject(tableau_xhr_activeX[value]);
                    break;
                } catch (error) {}
            }

        } else {
            xhr = new XMLHttpRequest();
        }
    } else {
        return null;
    }

    return xhr;
}

function createCookie(name, value, days) {
    let expires;

    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }

    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
}

function openMultiShad(id) {
    $("#" + id).addClass("actif");
    $("body").addClass("fixed");

    $("#shad").addClass("actif").on("click", function () {
        closeMultiShad(id);
    });
}

function show_popup(elem) {
    openMultiShad(elem);
}

function closeMultiShad(id) {
    $('#' + id).removeClass('actif');
    $('.item_product').removeClass('actif');
    $('.item.gondole').removeClass('actif');
    $('body').removeClass('fixed');
    $('.tag_button').removeClass('actif');

    setTimeout(function () {
        $('.shad').removeClass('actif');
        $('.shad').removeClass('for_gdt');
    }, 200);

    if ($('.tag_button').length) {
        $(".tag_button").fadeIn();
    }
}

function close_popup(elem) {
    closeMultiShad(elem)
}

/**
 * Remove product from cart header rollover
 * @param {*} basket_id
 * @param {*} prod_id
 * @param {*} qte
 */
function ajax_RemoveBasketProd(basket_id, prod_id, qte, type) {
    let isService = (type == 'service') ? true : false;
    $.ajax({
        type: 'post',
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte,
            is_service: isService
        },
        url: path_relative_root + 'ajax_removeBasketProd.php',
        success: function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON error', e);
                return;
            }

            const htmlContent = $(res.datas.html);
            const cartListContent = htmlContent.find('#cart_list').html();
            let rolloverTxtElement;

            if (cartListContent != undefined) {
                $('#cart_top #cart_list').html(cartListContent);

                const nbProd = htmlContent.find('i.nbProd');
                rolloverTxtElement = $(nbProd).closest('a.cart.rolloverTxt').clone();
                rolloverTxtElement.removeClass('cart').addClass('cart_full');
            } else {
                $('#cart_top #cart_list').remove();
                $('#cart_top .wrap_tot_panier').remove();

                $('#cart_top .rollover_title').append(`<div class="no_product">` + Translator.translate('your_basket_empty') + `</div>`);
                rolloverTxtElement = $(htmlContent).closest('a.cart.rolloverTxt').clone();
            }

            if (rolloverTxtElement != undefined) {
                $('#cart_top .rolloverTxt').replaceWith(rolloverTxtElement);
            } else {
                console.error('Content for .rolloverTxt is undefined');
            }

            if (typeof dataLayer_v3 !== 'undefined' && typeof dl_v3_impressions !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && prod_id !== null && prod_id !== undefined) {
                DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, prod_id, "remove_from_cart", qte);
            }
        }
    });
}

// The function isInViewport is used to check if the element appears on the screen. If it does, it returns true and the click is simulated, otherwise not. This function is called in the click condition during scrolling.
function isInViewport(element) {
    let elementTop = $(element).offset().top;
    let elementBottom = elementTop + $(element).outerHeight();
    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

function initItemSlider() {
    $('.swiper_container_look').each(function () {
        var swiperLookId = $(this).parents('.item.item_product').attr('id');

        // Prevent initialized sliders to go back to the first frame during scroll
        if (isInViewport($(this)) && !$(this).hasClass('initialized')) {
            new Swiper((this), {
                roundLengths: true,
                slidesPerView: 1,
                spaceBetween: 1,
                lazy: true,
                loop: true,
                preloadImages: false,
                watchOverflow: true,
                navigation: {
                    nextEl: "#" + swiperLookId + " " + ".swiper-button-next",
                    prevEl: "#" + swiperLookId + " " + ".swiper-button-prev",
                },
            });

            $(this).addClass('initialized');

            $(this).find('.swiper_container_look_buttons').show();
        }
    });
}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, idCouleur, itm_rr_id, idTaille) {
    itm_rr_id = (itm_rr_id !== undefined && itm_rr_id !== null) ? itm_rr_id : '';
    var form = document.getElementById('prod_info_' + id + itm_rr_id);
    var formAchatExpressV1 = $('#formAchatExpressV1[data-produitid="'+ id +'"]').attr('id') === 'formAchatExpressV1';

    if (form == null && !formAchatExpressV1) {
        itm_rr_id = '_' + itm_rr_id;
        form = document.getElementById('prod_info_' + id + itm_rr_id);
    }

    if (formAchatExpressV1) {
        form = document.getElementById('formAchatExpressV1');
    }

    var plnb = form.getValue('produit_lot_nb');
    var is_achat_express = form.getValue('isAchatExpressV2') == 1;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';
    var productId = form.getValue('produit_id');
    var pcol = form.getValue('idCouleur_' + id + itm_rr_id);

    if (typeof (pcol) == 'undefined') {
        var pcol = form.getValue('idCouleur');
    }

    var colorId = form.getValue(pcol);

    if (colorId == '0') {
        colorId = 'none';
    }

    var psze = form.getValue('idTaille');
    var sizeId = form.getValue(psze);

    if (typeof idTaille !== "undefined") {
        var sizeId = idTaille;
    } else {
        var psze = form.getValue('idTaille');
        var sizeId = form.getValue(psze);
    }

    if (idCouleur !== undefined) {
        colorId = idCouleur;
        $('#' + pcol).val(idCouleur);
        $("#color_" + idCouleur).attr("checked", "checked");
    }

    if (productId !== '' && colorId !== '' && sizeId !== '') {
        var data = {
            idprod: productId,
            idcouleur: colorId,
            idtaille: sizeId,
            isGlobalEActive: isGlobalEActive,
            json: '1'
        };

        if (from == 'basket') {
            data.suff = productId;
        }

        if (plnb) {
            data.produit_lot_nb = plnb;
        }

        $.ajax({
            url: path_relative_root + 'ajax_liste_qte.php',
            type: 'get',
            async: false, // Should be async but would then need a loader so...
            dataType: 'json',
            data: data,
            success: function (res) {
                if (is_achat_express) {
                    if (res.inventory.enableAddToCart) {
                        $("#bloc_add_alert" + elem).hide();
                        $("#bloc_size_not_available" + elem).hide();
                        $("#prod_info" + elem + " .rollover_left").show();
                        $("#prod_info" + elem + " .bloc_add_color").show();
                    } else {
                        $("#bloc_add_alert" + elem).show();
                        $("#bloc_size_not_available" + elem).show();
                        $("#prod_info" + elem + " .rollover_left").hide();
                        $("#prod_info" + elem + " .bloc_add_color").hide();
                    }

                } else { // Product Page
                    if (res.inventory.enableAddToCart) {
                        $("#btn_alert_stock").hide();
                        $(".product_info_wrapper #size_is_not_available").hide();
                        $("#btn_add_cart").show();
                    } else {
                        $("#btn_alert_stock").show();
                        $(".product_info_wrapper #size_is_not_available").show();
                        $("#btn_add_cart").hide();
                    }
                }

                if (window.dataLayer !== undefined) {
                    pushIntoDatalayer(res.dataLayer_object);
                }
            }
        });
    }
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id, type, modeleItemId, has_stock) {
    var control, prod_id;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);

    if (this.checked) {
        control = form.elements.namedItem(form.getValue('idCouleur'));

        if (typeof control == "undefined") {

            var produit_id = document.getElementById('produit_id').value;
            control = document.querySelector('#couleurProd_' + produit_id);
        }

        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();

        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur', 'product', itm_rr_id, 'colorChange');
        } else {
           // Check if the product is already in the wishlist
            if (!is_achat_express_v2) {
                checkProductInWishlist(prod_id, itm_rr_id);
            }

            var img = $(this).data('pic');

            if (modeleItemId) {

                changeVisu('slide-' + modeleItemId, img);
            } else {

                changeVisu('img_large', img);
            }

            if (type == 'product') {

                $('.mainSlideFp .swiper-wrapper .swiper-slide-active img').attr('src', img);
            } else if (type == 'association' || type == 'recently') {

                $('.swipper-slide').removeClass('active');
                $('#itm-' + prod_id).addClass('active');
                if ($('#itm-' + prod_id + ' .w-slider-container .w-slider-wrapper').length) {

                    $('#itm-' + prod_id + ' .w-slider-container .w-slider-wrapper').find('span:first').find('img').attr('src', img);
                } else {

                    $('#itm-' + prod_id + ' .swiper-container .swiper-wrapper').find('img:first').attr('src', img);
                }
            }

            if ($('.product_page .form_itm.modele').length > 0) {

                $('.mainSlideFp .swiper-wrapper .swiper-slide-active img').attr('src', img);
            } else {

                $('#itm-' + prod_id + ' .w-slider-container .w-slider-wrapper').find('span:first').attr('data-src', img);
            }

            if ($('#itm-' + prod_id).hasClass('one_size')) {
                $('#itm-' + prod_id).find('.prodSize').click();
            } else {
                getAvailableQuantity(prod_id, false, '', false, itm_rr_id);
                setAvailableSizes.call(this);
            }
        }

        setTimeout(function () {
            $(window).scrollTop($(window).scrollTop() - 3);
        }, 300);
    }
}

// In the product sheet, display an email field so that we can notify when the product is available
function lightboxAlertStock(productId) {
    if (typeof productId === "undefined") {
        productId = '';
    } else {
        productId = '_' + productId;
    }

    let $taille_id = $("#tailleProd").val()
    let $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }

        return;
    } else if ($couleur_id == '') {
        alert(translate('js_error_couleur'));
        return;
    }

    let isFormVisible = $(this).find('.bloc_add_alert_form').is(':visible');
    let isMessageVisible = $(this).find('.bloc_add_alert_confirmation').is(':visible');
    let isErrorVisible = $(this).find('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {
        if (isFormVisible) {
            $('#btn_alert_stock' + productId).slideDown('fast');
        }

        if (isMessageVisible) {
            $('.bloc_add_alert_confirmation').slideUp('fast');
        }

        if (isErrorVisible) {
            $('.bloc_add_alert_erreur').slideUp('fast');
        }
        $('#alert_return' + productId).hide();
    } else {
        $('#bloc_add_alert_form' + productId).slideDown('fast');
        $('#btn_alert_stock' + productId).slideUp('fast');
        $('#alert_return' + productId).show();
        $('#keep_alive_stock' + productId).show();
    }
}

function closeAlerteStock(elem) {
    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
    $("#prod_info_" + elem + " .rollover_left").show();

    // Reset mail input
    $('.wrapper_add_color_' + elem).show().removeClass("loading");
    $("#prod_info_" + elem).find('.form_submit.bloc_add_color .button.loader').removeClass('loading');
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
    $('#ligne_pointure_' + elem).show();
    $('#ligne_pointure_' + elem + ' input').prop('checked', false);
    $('#ligne_couleur_' + elem).show();
    $("#prod_info_" + elem).parents('.wrap_rolloverproduit').removeClass('size_selected');

    if ($('body').hasClass('wishlist')) {
        $('.bloc_add_alert_confirmation').hide();
        $('#btn_alert_stock_' + elem).show();
        $('#keep_alive_stock_' + elem).hide();
        $('#alert_return_' + elem).hide();
    }
}

function eventButton() {
    $('.prodColor').on("click", function() {
        "use strict";
        checkSubmitState();
        checkSubmitStateProduct();
    });

    $('.prodSize').on("click", function() {
        "use strict";
        checkSubmitState();
        checkSubmitStateProduct();
    });

    let addCartWrapper = $(".btnAddBasketWrapper");
    let disabledBtnTooltip = $(".disabledBtnTooltip");

    addCartWrapper.on({
        mouseover: function() {
            if ($(this).hasClass("inactiv") && disabledBtnTooltip.text() != "") {
                disabledBtnTooltip.addClass('blink');
                disabledBtnTooltip.addClass('actif');
            }
        },
        mouseout: function() {
            disabledBtnTooltip.removeClass('blink');
            if ($(this).hasClass("inactiv") && disabledBtnTooltip.text() != "") {
                disabledBtnTooltip.addClass('actif');
            }
        }
    });
}

function loadProduct(new_id, type, page, itm_rr_id, triggerType, is_regroup = true) {
    var form = this;
    var old_id = this.getValue('produit_id');
    var cpt = this.getValue('cpt');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;
    triggerType = (triggerType !== undefined) ? triggerType : '';

    //Get original width and height of changed item, preserving style
    var oldModel = $(this).attr('id') !== '' ? $(this).attr('id').replace('prod_info_', '#itm-') : '',
        newModel = oldModel !== '' ? oldModel.replace('_', '-') : '',
        newWidth = $(newModel).css('width');

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express = this.getValue('isAchatExpressV2') == 1;
    var formLightbox = document.forms['prod_info_' + old_id];
    if (typeof formLightbox !== "undefined" && formLightbox.length) {
        var achatLightbox = formLightbox.getValue('is_achat_express');
    }

    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id: old_id,
        produit_principal: old_id,
        couleurProd: col_id,
        tailleProd: sze_id,
        act: act,
        qteProd: qte_itm,
        hdn_product_id: old_id,
        hdn_is_model: is_model,
        produit_id_new: new_id,
        type: type,
        type_page: (page !== undefined && page !== '') ? page : 'product',
        is_achat_express: achatLightbox ? 1 : 0,
        is_achat_express_v2: is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod: cpt_prod - 1
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_get_product_regroup_ref.php',
        data: data,
        success: function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {
                var datas = res.datas;

                if (data.type_page == 'product') {
                    var $html_main = $(stripCombo(datas.html_main));
                    var color = $("#ligne_couleur").html();

                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var info_wrapper = $('.product_info_wrapper', $html_main);
                    var zoom_box_wrapper = $('#zoom_box_wrapper', $html_main);

                    if (data.is_achat_express_v2) {
                        if (is_regroup) {
                            // Photo et information
                            var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');
                            $('#itm-' + itm_id).replaceWith($html_main);

                            var new_itm_id = '#itm-' + new_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');
                            if ($(new_itm_id).hasClass('one_size')) {
                                $(new_itm_id).find('.prodSize').click();
                            }
                            $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                            $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                        }
                    } else {

                        var $html_middle = $(stripCombo(datas.html_middle));
                        var assoc_product_wrapper = $('.assoc_product', $html_main);
                        var assoc_rayon_wrapper = $('.assoc_rayon', $html_main);
                        var cms_page_wrapper = $('.product_cms_bloc', $html_main);

                        $('.item_price_wrapper', $html_main).next('div').after($html_middle).remove();

                        var contents = $('<div />').html($html_main);

                        var video = $('.wrapper_video', contents);
                        if (achatLightbox)  {
                           // Photo and information
                            $('#lightbox_achat_express .product_main_wrapper').html(main_wrapper.html());

                            // Description component, delivery, size guide
                            $('#lightbox_achat_express  .product_info_wrapper').html(info_wrapper.html());
                        } else {
                            // Photo and information
                            $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());

                            // Description component, delivery, size guide
                            $('.product_info_wrapper', '#site_global_wrap').html(info_wrapper.html());

                            // Association produit
                            $('.pos_sticky .assoc_product', '#site_global_wrap').html(assoc_product_wrapper.html());

                            // Association rayon
                            $('.assoc_rayon', '#site_global_wrap').html(assoc_rayon_wrapper.html());

                            // Cms page module
                            $('.product_cms_bloc', '#site_global_wrap').html(cms_page_wrapper.html());

                            $('.breadcrumb.current').html('<span>' + datas.product.titreobjet + '</span>');

                            //zoom box
                            $('#zoom_box_wrapper').html(zoom_box_wrapper.html());
                        }

                        initFpSliders();

                        $("#ligne_couleur").html(color);
                        $('#videoBox', '#site_global_wrap').remove();
                        $('#shade').after(video);
                        $("#btn_add_cart").prop('disabled', false);
                        var elem = datas.product.id + (itm_rr_id !== '' ? itm_rr_id : '');
                        if ($('#itm-' + elem).hasClass('one_size')) {
                            $('#itm-' + elem).find('prodSize').click();
                        } else {
                            getAvailableQuantity(datas.product.id, data.qteProd, '', datas.product.color.id, itm_rr_id, datas.product.size.id);
                        }

                        setTimeout(function () {
                            checkSubmitStateProduct();
                            eventButton();
                            eventSizeButton();
                        }, 0);
                    }
                }

                if (typeof $('#' + old_id + '-' + cpt) !== "undefined") {
                    $('#' + old_id + '-' + cpt).attr("id", datas.product.id + "-" + cpt);
                }

                checkProductInWishlist(datas.product.id, itm_rr_id);

                if (achatLightbox) {
                    refreshEventsLoadProduct("#lightbox_achat_express");
                } else {
                    refreshEventsLoadProduct();
                }
            } else {
                $('#btn_error').fadeIn();
            }
        },
        complete: function () {
            if (is_wishlist) {
                // Event on the add to wishlist button
                $("#addToWishlistButton").on("click", addToWishlist);
            }

            if ($("#jqzoom .swiper-slide").length > 0) {
                new Swiper('#jqzoom', {
                    direction: 'vertical',
                    slidesPerView: 1,
                    mousewheel: true,
                    navigation: {
                        nextEl: '.thumbNext',
                        prevEl: '.thumbPrev',
                    },
                    thumbs: {
                        swiper: productThumbs
                    },
                    breakpoints: {
                        1300: {
                            direction: 'horizontal',
                        },
                    },
                    preloadImages: false,
                    lazy: true,
                    loadPrevNext: true,
                    loadOnTransitionStart: true
                });
            }

            //Simulates scroll (2px) to load images
            if (!$('.product_page').length) {
                $(window).scrollTop($(window).scrollTop() + 2);
            }

            initItemSlider();

            cms_look_popup();

            if (typeof lazyloadImage === 'function') {
                lazyloadImage();
            }
        }
    });
}

// Refresh product after ajax event
function refreshEventsLoadProduct(idElt) {
    if ($(".product_page")) {
        initFpSliders(idElt);

        if (wShop.$refs.wAlertestockForm) {
            wShop.$refs.wAlertestockForm.reloadBloc += 1;
        }

        if (!$(".w-alertestock-form").length) {
            new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
        }

        initItemSlider();
    }
}

/**
 * Express buy
 * @param product_id : id du produit
 * @param regroup_ref_id : id du regroupement (si existant)
 * use in : app/views/_components/product/achat_express.php
 */
function achatExpressV2(product_id, regroup_ref_id) {
    var btn = $(this);
    var loader = btn.next('.loader');

    // Loader
    loader.addClass('loading');

    var elem = regroup_ref_id ? '_' + product_id + regroup_ref_id : '_' + product_id;
    var form = btn.parent().parent();

    var color_id = form.find('input[name="itm_color"]:checked').attr("value");
    var size_id = form.find('input[name="itm_size"]:checked').attr("value");

    // Check if the selected size is not disabled, which allows the stock alert to be displayed
    var tailleProdCrossSell = $('#qteProd_' + product_id + regroup_ref_id);

    if (($('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)) != undefined && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).length == 1 && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).hasClass('disabled')) || ($('#tailleProd_' + product_id + '_' + regroup_ref_id).value == '0') || (typeof tailleProdCrossSell[0] != "undefined" && tailleProdCrossSell[0].value == "0")) {
        $(this).parent().hide();

        if ($(this).parents('.wrap_rolloverproduit').find('#bloc_add_alert' + elem).length) {
            $(this).parents('.wrap_rolloverproduit').find('#bloc_add_alert' + elem).show();
        } else if ($(this).parents('.wrap_rolloverproduit').find('#bloc_size_not_available' + elem).length) {
            $(this).parents('.wrap_rolloverproduit').find('#bloc_size_not_available' + elem).show();
        }

        $(this).parents('.wrap_rolloverproduit').removeClass('size_selected');
        $(this).parents('.wrap_rolloverproduit').find('.ligne_form').hide();
        $(this).parents('.wrap_rolloverproduit').find('input.prodSize').prop('checked', false);

        btn.removeClass('loading');
    } else { // We add the product correctly by verifying the size and color
        // Check if the person has selected the size and color
        setTimeout(function () {
            $(this).parents('.wrap_rolloverproduit').removeClass('size_selected');
            if ($(this).parents('.wrap_rolloverproduit').find('.ligne_form').children('.form_itm').length > 2) {
                $(this).parents('.wrap_rolloverproduit').find('.ligne_form').show();
            }
            $(this).parents('.wrap_rolloverproduit').find('input.prodSize').prop('checked', false);
        }, 0);

        if (color_id !== '' && size_id !== '') {
            if ($('.wrapperCartAssociations').length) {
                waitingAddToBasket(event, form, 'basket', regroup_ref_id, 1);
            } else {
                waitingAddToBasket(event, form, '', regroup_ref_id, 1);
                closeMultiShad('lightboxAchatExpressV1');
            }
        } else if (color_id === '' && size_id === '') {
            alert(Translator.translate('choose_a_size_and_a_color'));
            // Loader
            loader.removeClass('loading');
        } else if (color_id === '') {
            alert(Translator.translate('choose_a_color'));
            // Loader
            loader.removeClass('loading');
        } else if (size_id === '') {
            alert(Translator.translate('choose_a_size'));
            // Loader
            loader.removeClass('loading');
        }
    }
}

function closeSizeNotAvailable(elem) {
    $("#prod_info_" + elem).parents('.wrap_rolloverproduit').removeClass('size_selected');
    $("#prod_info_" + elem).parents('.wrap_rolloverproduit').find('.rollover_left').css("display", "flex");
    $("#bloc_size_not_available_" + elem).hide();
    $("#ligne_pointure_" + elem).show();
    $("#ligne_pointure_" + elem).find('.form_itm input').prop( "checked", false );

    // Deactivate AddToCart's loader after closing the alert texte
    $("#prod_info_" + elem).find('.form_submit.bloc_add_color .button.loader').removeClass('loading');
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id, achatExpress) {
    if ($("input[name='options']:checked").length > 0) {
        return addConfiguredProduct();
    }

    let id = '';

    let is_achat_express, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, is_lightbox_specifique;

    if ($("body").hasClass("product_page") && $(form_elm).hasClass("product_info")) {
        produit_id = form_elm.getValue('produit_id');
        suff = form_elm.getValue('suff');
        panier_id = form_elm.getValue('panier_id');
        titreObjet = form_elm.getValue('titreObjet');
        idTaille = form_elm.getValue('idTaille');
        lastTaille = form_elm.getValue('lastTaille');
        idCouleur = form_elm.getValue('idCouleur');
        lastCouleur = form_elm.getValue('lastCouleur');
        toEval = form_elm.getValue('toEval');
        page_name = form_elm.getValue('page_name');
        path_web = form_elm.getValue('path_web');
        enable_omniture = form_elm.getValue('enable_omniture');
        is_not_produit = form_elm.getValue('is_not_produit');
        is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
        is_achat_express = form_elm.getValue('isAchatExpressV2') == 1;
    } else {
        produit_id = form_elm.find('input[name="produit_id"]').attr("value");
        suff = form_elm.find('input[name="suff"]').attr("value");
        panier_id = form_elm.find('input[name="panier_id"]').attr("value");
        titreObjet = form_elm.find('input[name="titreObjet"]').attr("value");
        idTaille = form_elm.find('input[name="idTaille"]').attr("value");
        lastTaille = form_elm.find('input[name="lastTaille"]').attr("value");
        idCouleur = form_elm.find('input[name="idCouleur"]').attr("value");
        lastCouleur = form_elm.find('input[name="lastCouleur"]').attr("value");
        toEval = form_elm.find('input[name="toEval"]').attr("value");
        page_name = form_elm.find('input[name="page_name"]').attr("value");
        path_web = form_elm.find('input[name="path_web"]').attr("value");
        enable_omniture = form_elm.find('input[name="enable_omniture"]').attr("value");
        is_not_produit = form_elm.find('input[name="is_not_produit"]').attr("value");
        is_lightbox_specifique = form_elm.find('input[name="is_lightbox_specifique"]').attr("value");
        is_achat_express = form_elm.find('input[name="isAchatExpressV2"]').attr("value") == 1;
    }

    if (typeof achatExpress !== "undefined") {
        is_achat_express = achatExpress;
    }

    let loader;

    evt.preventDefault();

    if (from === undefined) {
        from = '';
    }

    if (is_not_produit == true) {
        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {
        loader = $(this.nextElementSibling);
        loader.addClass('loading');
    } else {
        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {
        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 0, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id, form_elm);
    }
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id, form) {
    var id = '';
    var prix_id = 'prixU';
    itm_rr_id = itm_rr_id !== undefined && itm_rr_id !== null ? itm_rr_id : '';

    if (from == 'basket' || is_not_produit == true || is_achat_express) {
        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, form);

    if (array_panier != false) {
        if (from == 'basket') {
            location.reload();
            return false;
        }

        if (typeof (array_panier.error) == 'undefined') {
            try {
                if (array_panier.length >= 0 && array_panier[6] !== undefined) {
                    var dlv3_objet = JSON.parse(array_panier[6]);
                    DataLayerTrigger.addToCart(dlv3_objet['items'], dlv3_objet['actionField'], is_achat_express);
                }
            } catch (error) {
                console.log("GTM disabled");
            }

            omnitureEvent = '';
            var response = ajax_file(path_relative_root + 'ajax_show_basket.php');
            $("#cart_top").html(response);
            CartShow();

            setTimeout(function () {
                if ($('#size_list_' + produit_id + ' .form_itm').length > 1) {
                    $('#prod_info_' + produit_id).parent('.wrap_rolloverproduit').removeClass('size_selected');
                }
            }, 1000);

            // Reloads the page to update the product list in the cart (product added via tdg)
            if ($('body.cart.step_1').length) {
                window.location.reload();
            }

            if ($('#lightbox_achat_express').hasClass('actif')) {
                $('#lightbox_achat_express').removeClass('actif').fadeOut();
                closeMultiShad();
            }

            if (array_panier[1] === 0) {
                $('#cart_length').html(array_panier[1] + ' ' + Translator.translate('items'));

                omnitureEvent = '';
            } else if (array_panier[1] == 1) {
                $('#cart_length').html(array_panier[1] + ' ' + Translator.translate('item'));
                $('.mini_cart', '#top_wrapper').attr('href', path_web + create_link('order_basket'));

                omnitureEvent = 'scOpen,scAdd'; // The first product added to the cart
            } else {
                $('#cart_length').html(array_panier[1] + ' ' + Translator.translate('items'));
                $('.mini_cart', '#top_wrapper').attr('href', path_web + create_link('order_basket'));

                omnitureEvent = 'scAdd'; // There is already a product in the cart
            }
        } else {
            alert(array_panier.error.msg, false);

            if (loader) {
                loader.removeClass('loading');
            } else {
                $("#bloc_btn_active" + id).attr('class', '');
                $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
            }
            return false;
        }

        if (loader) {
            loader.removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {
            void (s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void (s.t());
        }

        $("#shad").after($("#modbox"));
        $("#lightbox_achat_express, .lightbox_achat_express").hide();

        newshowmodal(produit_id, is_not_produit);
    } else {
        if (loader) {
            loader.removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
        return false;
    }

    eval(toEval);

    if (array_panier != false) {
        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {
            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }

    $('.loader').removeClass('loading');
    return false;
}

function newUpdatePanier(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur, form_elm) {
     // If we are on the product page
    if ($("body").hasClass("product_page") && $(form_elm).hasClass("product_info")) {
        if (i != '') {
            i = '_' + i;

            prixU = $('#prixU' + i).val();
        } else {
            i = "_" + idProduit;
        }

        var couleurs = $('#couleurProd' + i);

        if ($('#produit_id').val() == idProduit || Object.keys(couleurs).length === 0) {
            couleurs = $('#couleurProd');
        }

        var tailles = $('#tailleProd' + i);

        if (Object.keys(tailles).length === 0) {
            tailles = $('#tailleProd');
        }
    } else {
        if (i != '') {

            i = '_' + i;

            prixU = form_elm.find('#prixU' + i).val();
        } else {
            i = "_" + idProduit;
        }

        var couleurs = form_elm.find('#couleurProd' + i);

        if ($('#produit_id').val() == idProduit || Object.keys(couleurs).length === 0) {
            couleurs = form_elm.find('#couleurProd' + i);
        }

        var tailles = form_elm.find('#tailleProd' + i);

        if (Object.keys(tailles).length === 0) {
            tailles = form_elm.find('#tailleProd' + i);
        }
    }

    var alertbox = $('#alertbox1');
    var res = '';
    var html_err = '';
    var flagError = false;

    html_err = '<div class="txt_alert"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="button primary">OK</a></div>';

    if (alertbox.length == 0) {
        alertbox = $('<div id="alertbox1" class="lightbox">' + html_err + '</div>');
        $('body').append(alertbox.hide());
    }

    if ($("body").hasClass("product_page") && $(form_elm).hasClass("product_info")) {
        var qtyInput = $('#qteProd' + i);
        if (qtyInput.length == 0) {
            qtyInput = $('#qteProd');
        }
        if (tailles.length && tailles.val() == '') {
            res = Translator.translate('js_error_size');
            flagError = true;

        } else if (couleurs && couleurs.val() == '') {

            res = Translator.translate('js_error_couleur');
            flagError = true;

        } else if (qtyInput.val() == '') {
            res = Translator.translate('js_error_quantity');
            flagError = true;
        }

        if (flagError) {
            alertbox.find('.txt_alert').html(res);
            openMultiShad('alertbox1', 'fixed');

            return false;
        } else {
            var qte = 0;
            var quantity = $('#qteProd' + i);

            if (Object.keys(quantity).length === 0) {
                quantity = $('#qteProd');
            }

            if (typeof quantity !== "undefined") {
                var qte = quantity.val();
            }

            var idCouleur = 0;
            var idTaille = 0;

            if (couleurs && couleurs.val() != 'none') {
                idCouleur = couleurs.val();
            }

            if (tailles && tailles.val() != 'none') {
                idTaille = tailles.val();
            }

            var array_response = [];
            var response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);

            if (response) {
                if (response.indexOf("//") <= 0) {
                    try {
                        array_response = JSON.parse(response);
                    } catch (e) {
                        console.error('JSON ERROR: could not parse data');

                        return false;
                    }
                } else {
                    array_response = response.split("//");
                    var designation = array_response[0];

                    designation += ' ' + titreObjet;

                    if (document.getElementById(idElTaille)) {
                        if (document.getElementById(idElTaille).selectedIndex) {
                            designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;
                        } else if (lastTaille != '') {
                            designation += ' - Taille ' + lastTaille;
                        }
                    }

                    if ($('[name="' + idElCouleur + '"]').length > 0) {
                        if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                            designation += ' - ' + $('[name="' + idElCouleur + '"]:checked').get(0).nextElementSibling.title.trim();
                        } else if (lastCouleur != '') {
                            designation += ' - ' + lastCouleur;
                        }
                    }

                    array_response[0] = designation;
                }
            }

            return array_response;
        }
    } else {
        var sizeCheckedValue = form_elm.find("input[name='itm_size']:checked").attr("value");
        if (tailles.length && tailles.val() == '' && sizeCheckedValue == '') {
            res = Translator.translate('js_error_size');
            flagError = true;
        } else if (couleurs && couleurs.val() == '') {
            res = Translator.translate('js_error_couleur');
            flagError = true;
        } else if (form_elm.find('#qteProd' + i) && form_elm.find('#qteProd' + i).attr("value") == '') {
            res = Translator.translate('js_error_quantity');
            flagError = true;
        }

        if (flagError) {
            alertbox.find('.txt_alert').html(res);
            openMultiShad('alertbox1', 'fixed');

            return false;
        } else {
            var qte = 0;
            var quantity = form_elm.find('#qteProd' + i);

            if (Object.keys(quantity).length === 0) {
                quantity = form_elm.find('#qteProd');
            }

            if (typeof quantity !== "undefined") {
                var qte = quantity.val();
            }

            var idCouleur = 0;
            var idTaille = 0;

            if (couleurs && couleurs.val() != 'none') {
                idCouleur = couleurs.val();
            }

            if (tailles && tailles.val() != 'none' && tailles.val() != '') {
                idTaille = tailles.val();
            } else if (sizeCheckedValue != '') {
                idTaille = sizeCheckedValue;
            }

            var array_response = [];
            var response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);

            if (response) {
                if (response.indexOf("//") <= 0) {
                    try {
                        array_response = JSON.parse(response);
                    } catch (e) {
                        console.error('JSON ERROR: could not parse data');

                        return false;
                    }
                } else {
                    array_response = response.split("//");
                    var designation = array_response[0];

                    designation += ' ' + titreObjet;

                    if (document.getElementById(idElTaille)) {
                        if (document.getElementById(idElTaille).selectedIndex) {
                            designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;
                        } else if (lastTaille != '') {
                            designation += ' - Taille ' + lastTaille;
                        }
                    }

                    if ($('[name="' + idElCouleur + '"]').length > 0) {
                        if ($('[name="' + idElCouleur + '"]:checked').length > 0) {
                            designation += ' - ' + $('[name="' + idElCouleur + '"]:checked').get(0).nextElementSibling.title.trim();
                        } else if (lastCouleur != '') {
                            designation += ' - ' + lastCouleur;
                        }
                    }

                    array_response[0] = designation;
                }
            }

            return array_response;
        }
    }
}

/**
 * Pushes data into the dataLayer.
 *
 * @param {Object|string} data - The data to be pushed into the dataLayer.
 *                               If the data is an object, it is pushed directly.
 *                               If the data is a string, it is parsed as JSON and then pushed.
 * @throws {SyntaxError} Will throw an error if the string cannot be parsed as JSON.
 */
function pushIntoDatalayer(data) {
    if (typeof (data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof (data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

// Trigger filters openeing
function filterTrigger(event) {
    if ($('#filter_sticky').length) {
        let trigger = $('#filter_sticky');
        let filter = $('#trigger_filtre');
        let shadMenu = $('#shad');
        let mainMenu = $('#wrapper_top_menu');

        if (!trigger.hasClass('triggered')) {
            $(event.target).addClass("is_checked");
            trigger.addClass('triggered');
            filter.addClass('show');
            shadMenu.addClass('actif for_filter');
            mainMenu.addClass('shaded');
            let wichFilter = $(event.target).attr("id");
            $('body').addClass('fixed');
            openMultiShad('filter_sticky');

            if ($("." + wichFilter).length) {
                $(".filters_dropdown_bloc input.filter_checkbox_hide").each(function (index) {
                    $(this).prop("checked", false);
                });

                $("." + wichFilter).prop('checked', true);
            }

            // ordre "oui" & "non"
            $(".filters_dropdown_bloc .selected_options .form_itm:first-child").each(function (index) {
                if ($(this).find('label').text() == Translator.translate("no")) {
                    $(this).parent().addClass('column_reverse');
                }
            });
        } else if (trigger.hasClass('triggered')) {
            $(".filters_dropdown_content input[type='checkbox']").each(function (index) {

                $(this).prop("checked", false);
            });

            $(".filtre_elem").each(function (index) {
                $(this).removeClass("is_checked");
            });

            $(event.target).addClass("is_checked");
            closeFiltre();
        }

        if (trigger.hasClass('triggered')) {
            shadMenu.on("click touch", function () {
                closeFiltre();
            });
        }
    }
}

// Reset all fiters
function initAllFilters(fromSearch = false) {
    let form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky');
    let ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {
        if ((elm.type === 'checkbox' || elm.type === 'radio')) {
            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.click();

                // Remove is_checked classes
                if ($(elm).hasClass('is_checked')) {
                    $(elm).removeClass('is_checked');
                }

                $(elm).prop('checked', false);
                $(elm).removeAttr('checked');
            }
        }
    });

    if (fromSearch === false) {
        generateNewBlocProd.call(form, 0);
    }

    $('a.tri_price.actif').removeClass('actif');

    $(".filtre_elem").each(function (index) {
        $(this).removeClass("is_checked");
    });

    $(".filters_dropdown_content input[type='checkbox']").each(function (index) {
        $(this).prop("checked", false);
    });

    let price_min = $('input[type=hidden][name=initial_min_price]').val();
    let price_max = $('input[type=hidden][name=initial_max_price]').val();
    let devise = siteCurrency.symbol_right;

    $('#amount1').val(price_min);
    $('#amount2').val(price_max);
    $('.'+$(this).attr('data-obj_id')).click();

    // Reset the slider position
    $("#slider-range").slider("option", { min: parseInt(price_min), max: parseInt(price_max), values: [parseInt(price_min), parseInt(price_max)], });

    // Reset to min/max values
    $('.handler_price.min').html(price_min + devise);
    $('.handler_price.max').html(price_max + devise);

    if (typeof wShop !== 'undefined' && typeof wShop.$refs.wRangeSlider !== 'undefined') {
        wShop.$refs.wRangeSlider.reloadTemplate();
        wShop.$refs.wRangeSlider.refreshPricesRange();
    }

    // Reset the slider position
    $('.ui-slider-range').css({'left':0, 'width':'100%'});
    $('.handler_price.min').parent().css('left', 0);
    $('.handler_price.max').parent().css('left','100%');

    setTimeout(function () {
        $("#validate_filter").html(Translator.translate("apply_filter"));
    }, 500);
}

// Count active filters in rayon
function countItemFilter(input, translation) {
    let filterName = input.name;
    let filters = $('input:checked[name=' + filterName + ']');
    let countFilters = filters.length

    if (countFilters > 0) {
        $("#trigger_filtre [data-filtername='" + filterName + "']").addClass('actif');
        if ($("#trigger_filtre [data-filtername='" + filterName + "']").length) {
            if ($("#trigger_filtre [data-filtername='" + filterName + "']").find('.count_filters').length) {
                $("#trigger_filtre [data-filtername='" + filterName + "'] .count_filters").html(countFilters);
            } else {
                $("#trigger_filtre [data-filtername='" + filterName + "']").append('<span class="count_filters">' + countFilters + '</span>');
            }
        } else if ($("#trigger_filtre [data-filtername='" + translation + "']").length) {
            if ($("#trigger_filtre [data-filtername='" + translation + "']").find('.count_filters').length) {
                $("#trigger_filtre [data-filtername='" + translation + "'] .count_filters").html(countFilters);
            } else {
                $("#trigger_filtre [data-filtername='" + translation + "']").append('<span class="count_filters">' + countFilters + '</span>');
            }
        }
    } else {
        if ($("#trigger_filtre [data-filtername='" + filterName + "'] .count_filters").length) {
            $("#trigger_filtre [data-filtername='" + filterName + "']").removeClass('actif');
            $("#trigger_filtre [data-filtername='" + filterName + "'] .count_filters").remove();
        }
    }
}

function filterTriggerIn(event) {
    // class added on input when user filters
    $(event.target).toggleClass("is_checked");
    $(event.target).click();
}

function filterWriteLabel(event) {
    const wichFilter = $(event.target).text();
    $("#filter_word").text("");
    $("#filter_word").text(wichFilter);
}

function closeFiltre() {
    $('#filter_sticky').removeClass('triggered');
    $('#trigger_filtre').removeClass('show');
    $('#shad_menu').removeClass('actif for_filter');
    $('#wrapper_top_menu').removeClass('shaded');
    $('body').removeClass('fixed');
    closeMultiShad("filter_sticky");
}

/**
 * To load all products on a page
 * @author
 * @since
 *
 * @param  String            type       [description]
 * @param  Number            idObj      [description]
 * @param  Number            page       [description]
 * @param  Number            nb_product [description]
 * @param  HTMLElement       btn        the button on which we clicked to load the elements (if undefined no problem)
 *
 * @return void
 */
function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollTop, fromScrollBottom, fromPagination = false) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        output = {};

    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky', fromPagination);
    if (output.page > 0) {
        output.page = $('#scroll_items .item_product:not(.push)').length;
    }
    output.handle_push = 'false';

    if (page !== 'all') {
        if (nb_product === 'all') {
            $('.see_all').html(
                '<img src="' + path_relative_root + 'img/loader.gif" width="20" height="20" />'
            );
        }

        if (bloc_prod_xhr) {
            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + 'ajax_reload_products.php',
            type: 'get',
            data: output,
            success: function (res) {
                var old_elm = document.getElementById('list_item');
                var content;
                var itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {
                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');

                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                var canonical = res[5];
                var prev = res[6];
                var next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr-fr']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

                var hrefPrev = $("link[rel^='prev']");
                var hrefNext = $("link[rel^='next']");
                var commentPrev = $('#href_prev');
                var commentNext = $('#href_next');

                changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                changeSeoElement(next, hrefNext, commentNext, 'next');

                if (content !== '') {
                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".autoLoad").fadeTo("fast", 0);
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('totalElems').value = itm_length;
                    paginate(page + 1);
                    old_elm.insertAdjacentHTML('beforeend', content);

                    // Add total product number of filtered items
                    $("#validate_filter").html(Translator.translate("apply_filter") + " (" + itm_length +")");

                    if (nb_product === 'all') {
                        $('.see_all').html('');
                    }

                    initDailymotionVideos();
                }

                setTimeout(function () {
                    $('.productSwiper').each(function () {
                        if ($(this).hasClass('swiper-container-initialized')) {
                            this.swiper.update();
                        }
                    } );

                    initItemSlider()
                }, 400);
            },
            complete: function () {
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                if (!fromScrollBottom && !fromScrollTop) {
                    setTimeout(function () {
                        $("html, body").animate({
                            scrollTop: 0
                        }, 200);
                    }, 250);
                    //reset initiialPage if from filter
                    $("#initial_page").trigger("change");
                }

                total_element = form.getValue('totalElems', parseFloat);

                // Display number of product on the right side of top ray filters
                $('#nbrFilteredProd span').html(total_element);

                // UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                $(".seo_nb_product").html(total_element + ' ' + Translator.translate('label_produit'));
                var productLoaded = $('#list_item .item:not(".push")').length;
                var percentLoaded = (productLoaded * 100 / total_element);

                if (productLoaded >= total_element) {
                    $('.nbProdDisplayed').html(Translator.translate('pagerTxt', productLoaded, productLoaded));
                } else {
                    $('.nbProdDisplayed').html(Translator.translate('pagerTxt', productLoaded, total_element));
                }
                $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

                $('.loader_scroll').removeClass('loading');

                if (productLoaded >= total_element) {
                    $('.loader_scroll').hide();
                } else {
                    $('.loader_scroll').show();
                }

                setTimeout(function () {
                    $('.pagerNav').addClass('appear');
                }, 500);

                initDailymotionVideos();
            }
        });
    }
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    page = page || 1;
    const page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop, true);
}

function paginate(page) {
    if (document.getElementById('filter_sticky')) {
        const form = document.getElementById('filter_sticky');
        const total_element = form.getValue('totalElems', parseFloat);
        const nb_prod = form.getValue('nb_prod', parseFloat);
        const nb_total_page = Math.ceil(total_element / nb_prod);
        const pagination = $('.pager_wrapper');
        const nb_prod_default = form.getValue('nb_prod_default', parseFloat);
        page = page || 1;

        $.ajax({
            url: path_relative_root + 'ajax_reload_pagination.php',
            type: 'get',
            data: {
                page: page,
                link: 'paginationGoToPage()',
                nb_total_page: nb_total_page
            },
            success: function (res) {
                let productLoaded = $('#list_item .item:not(".push")').length;
                let percentLoaded = (productLoaded * 100 / total_element);

                pagination.each(function () {
                    if (nb_prod != 1000 && nb_prod < total_element) {
                        this.innerHTML = res + '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product"></div>';
                    } else if (total_element > nb_prod_default) {
                        this.innerHTML = '<div class="nbProdDisplayed">' + Translator.translate('pagerTxt1') + ' ' + productLoaded + ' ' + Translator.translate('pagerTxt2') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product"></div>';
                    } else {
                        this.innerHTML = '';
                    }
                });

                initDailymotionVideos();
            }
        });
    }
}

function zoomImg(slide) {
    $('#zoom_box_wrapper').css('opacity', 0);
    $('body').addClass('fixed');

    window.setTimeout(function () {
        let zommMainVisu = $(".zommMainVisu");
        let zommThumbs = $(".zommThumbs");

        zommThumbs = new Swiper('.zommThumbs', {
            direction: 'vertical',
            slidesPerView: 6,
            spaceBetween: 5,
            navigation: {
                prevEl: '.zoomThumbsPrev',
                nextEl: '.zoomThumbsNext',
            },
            breakpoints: {
                1620: {
                    slidesPerView: 6,
                    spaceBetween: 5,
                },
            },
        });

        if ($('.zommMainVisu .swiper-slide').length > 1) {
            zommMainVisu = new Swiper('.zommMainVisu', {
                slidesPerView: 1,
                thumbs: {
                    swiper: zommThumbs
                },
                navigation: {
                    prevEl: '.zoomProdPrev',
                    nextEl: '.zoomProdNext',
                }
            });

            $('.zoomProdPrev, .zoomProdNext').addClass('visible');
        }

        if (slide == null) {
            if (typeof (productMainVisu) != 'undefined') {
                zommMainVisu.slideTo(productMainVisu.activeIndex);
            }
        } else {
            if ($('.zommThumbs .minivideo').length > 0) {
                zommMainVisu.slideTo(slide);
            } else {
                zommMainVisu.slideTo(slide - 1);
            }
        }
    }, 50);

    window.setTimeout(function () {
        $('#zoom_box_wrapper').css('opacity', 1);
    }, 0);

    openMultiShad('zoomBox');
}

// Find in store / Choose favorite store
function showpdv(is_find_store) {
    let shad, modbox, contents;

    $('html, body').animate({
        scrollTop: 0
    }, 500);

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax-distributeurs.php',
        success: function (res) {
            if (res) {
                shad = document.getElementById('shad');
                modbox = document.getElementById('pdvBox');

                contents = $('<div />').html(res);
                $('#pdvBox').removeClass('deployed');

                // Make sure modbox will show on top of shad
                $(shad).after(modbox);

                // Inject store locator into modbox
                $('.store_locator', modbox).html($('.storeloc_form', contents).add($('.liste_distributeur_wrapper', contents)));

                let listeMag = document.getElementById('liste_distributeur');
                listeMag.innerHTML = '';

                //wording
                if (is_find_store) {
                    $('.popup_title').html(Translator.translate('find_in_store'));
                    // Add-remove class on the container to differentiate CSS between: find it in store / choose preferred store
                    $('#pdvBox').addClass('find_art_in_store');
                    $('#pdvBox').removeClass('choose_fav_mag');
                } else {
                    $('.popup_title').html(Translator.translate('choose_favorite_store'));
                    $('#pdvBox').addClass('choose_fav_mag');
                    $('#pdvBox').removeClass('find_art_in_store');
                }

                initialize();
                geolocalisation();

                $(modbox).addClass('actif');
                $(shad).addClass('actif for_gdt');
                $(shad).on('click', closepdv);
                $('#content_pdvBox_new').hide();
                $('#content_pdvBox').show();
            }
        }
    });
}

function closepdv() {
    const shad = document.getElementById('shad');
    const modbox = document.getElementById('pdvBox');

    $(modbox).removeClass('actif');
    $(shad).removeClass('actif for_gdt');
}

function initFpSliders(elt) {
    if (typeof elt !== "string") {
        elt = '';
    }

    if ($('body.product_page').length) {
        var mainSlideFpId = elt + ".mainSlideFp";
        var mainSlideFp_img = elt + ".mainSlideFp .swiper-slide img.img_visu";
        var thumbSlideFpId = elt + ".thumbSlideFp";

        setTimeout(function () {
            var mainSlideFpHeight = $(mainSlideFp_img).height();
            $(thumbSlideFpId).height(mainSlideFpHeight);
        }, 1000);
    }

    /*  Product main slider */
    if ($(thumbSlideFpId + ' .swiper-slide').length > 1) {
        var thumbSlideFpSwiper = new Swiper(thumbSlideFpId, {
            direction: "vertical",
            slidesPerView: 'auto',
            spaceBetween: 8,
            watchOverflow: true,
            updateOnWindowResize: true,
            navigation: {
                prevEl: '.thumbsFpContainer .prevThumbFp',
                nextEl: '.thumbsFpContainer .nextThumbFp',
            },
        });

        var mainSlideFpSwiper = new Swiper(mainSlideFpId, {
            slidesPerView: 1,
            loop: true,
            roundLengths: true,
            updateOnWindowResize: true,
            thumbs: {
                swiper: thumbSlideFpSwiper
            },
            navigation: {
                nextEl: mainSlideFpId + ' .swiper-button-next',
                prevEl: mainSlideFpId + ' .swiper-button-prev',
            },
        });
    }

    /* Product color slider */
    $('body').on('mouseenter touchstart', '.fp_color_swiper', function () {
        if ($('.fp_color_swiper').length) {

            new Swiper('.fp_color_swiper', {
                slidesPerView: 'auto',
                navigation: {
                    nextEl: ".nextColorFp",
                    prevEl: ".prevColorFp ",
                },
            });
        }
    });

    /**
     * Product associations product slider
     */
    if ($('#assoc_product_slider_Fp').length) {

        new Swiper('#assoc_product_slider_Fp', {
            slidesPerView: 'auto',
            spaceBetween: 8,
            scrollbar: {
                el: "#assoc_product_slider_Fp .swiper-scrollbar",
                type: "progressbar",
                draggable: true,
                dragSize: 360,
            },
            breakpoints: {
                1140: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1360: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                1580: {
                    slidesPerView: 3,
                },
            },
        });
    }

    if ($('#assoc_product_slider').length) {

        if ($('#assoc_product_slider .swiper-slide').length == 3) {

            new Swiper('#assoc_product_slider', {
                slidesPerView: 2.8,
                spaceBetween: 5,
                navigation: {
                    nextEl: "#assoc_product_slider .slide_next",
                    prevEl: "#assoc_product_slider .slide_prev",
                },
                breakpoints: {
                    640: {
                      slidesPerView: 2.8,
                      spaceBetween: 5
                    },
                    1160: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                    },
                },
            });
        } else if ($('#assoc_product_slider .swiper-slide').length == 4) {

            new Swiper('#assoc_product_slider', {
                slidesPerView: 2.8,
                spaceBetween: 5,
                navigation: {
                    nextEl: "#assoc_product_slider .slide_next",
                    prevEl: "#assoc_product_slider .slide_prev",
                },
                breakpoints: {
                    640: {
                      slidesPerView: 2.8,
                      spaceBetween: 5
                    },
                    1160: {
                        slidesPerView: 4,
                        spaceBetween: 5,
                    },
                },
            });
        } else if ($('#assoc_product_slider .swiper-slide').length > 4) {

            new Swiper('#assoc_product_slider', {
                slidesPerView: 2.8,
                spaceBetween: 5,
                navigation: {
                    nextEl: "#assoc_product_slider .slide_next",
                    prevEl: "#assoc_product_slider .slide_prev",
                },
                scrollbar: {
                    el: "#assoc_product_slider .swiper-pagination",
                    type: "progressbar",
                    draggable: true,
                },
                breakpoints: {
                    640: {
                      slidesPerView: 2.8,
                      spaceBetween: 5
                    },
                    1160: {
                        slidesPerView: 4,
                        spaceBetween: 5,
                    },
                    1450: {
                        slidesPerView: 5,
                        spaceBetween: 5,
                    },
                },
            });
        } else {

            $('#assoc_product_slider').addClass('no_swiper');
        }
    }

    /* Product associations rayon slider */
    if ($('#assoc_rayon_slider').length) {

        if ($('#assoc_rayon_slider .swiper-slide').length == 3) {

            new Swiper('#assoc_rayon_slider', {
                slidesPerView: 2.8,
                spaceBetween: 5,
                navigation: {
                    nextEl: "#assoc_rayon_slider .slide_next",
                    prevEl: "#assoc_rayon_slider .slide_prev",
                },
                breakpoints: {
                    640: {
                      slidesPerView: 2.8,
                      spaceBetween: 5
                    },
                    1160: {
                        slidesPerView: 3,
                        spaceBetween: 5,
                    },
                },
            });
        } else if ($('#assoc_rayon_slider .swiper-slide').length == 4) {

            new Swiper('#assoc_rayon_slider', {
                slidesPerView: 2.8,
                spaceBetween: 5,
                navigation: {
                    nextEl: "#assoc_rayon_slider .slide_next",
                    prevEl: "#assoc_rayon_slider .slide_prev",
                },
                breakpoints: {
                    640: {
                      slidesPerView: 2.8,
                      spaceBetween: 5
                    },
                    1160: {
                        slidesPerView: 4,
                        spaceBetween: 5,
                    },
                },
            });
        } else if ($('#assoc_rayon_slider .swiper-slide').length > 4) {

            new Swiper('#assoc_rayon_slider', {
                slidesPerView: 2.8,
                spaceBetween: 5,
                navigation: {
                    nextEl: "#assoc_rayon_slider .slide_next",
                    prevEl: "#assoc_rayon_slider .slide_prev",
                },
                scrollbar: {
                    el: "#assoc_rayon_slider .swiper-pagination",
                    type: "progressbar",
                    draggable: true,
                },
                breakpoints: {
                    640: {
                      slidesPerView: 2.8,
                      spaceBetween: 5
                    },
                    1160: {
                        slidesPerView: 4,
                        spaceBetween: 5,
                    },
                    1450: {
                        slidesPerView: 5,
                        spaceBetween: 5,
                    },
                },
            });
        } else {

            $('#assoc_rayon_slider').addClass('no_swiper');
        }
    }

    /* Recently browsed articles slider */
    if ($('.product_page #productVisitedSwiper .swiper-slide.alt_sld_view').length > 1) {
        new Swiper('.product_page #productVisitedSwiper', {
            slidesPerView: 2,
            spaceBetween: 5,
        });
    }

    if ($('body.wishlist #productVisitedSwiper').length) {
        if ($('body.wishlist #productVisitedSwiper .alt_sld_view.swiper-slide').length > 3) {
            new Swiper('body.wishlist #productVisitedSwiper', {
                slidesPerView: 5,
                spaceBetween: 5,
                breakpoints: {
                    640: {
                      slidesPerView: 3,
                      spaceBetween: 5,
                    },
                    1160: {
                        slidesPerView: 4,
                        spaceBetween: 5,
                    },
                    1450: {
                        slidesPerView: 5,
                        spaceBetween: 5,
                    },
                },
            });
        }
    }

    if ($('body.product_page').length) {
        sticky_bar_fp();
    }
}

// Filter orders by type
function show_orders_by_type(elementID, type, parentPath, reload) {
    let str_year = '';
    let str_reload = '';
    const year = $("#year").val();

    if (year != '') {
        str_year = '&year=' + year;
    }

    if (reload !== undefined) {
        str_reload = '&reload=true';
    }

    // Manages tabs highlighting
    let i = 1;
    while (document.getElementById("com_onglet_" + i)) {
        if (type == i) {
            $("#com_onglet_" + i).addClass("actif");
        } else {
            $("#com_onglet_" + i).removeClass("actif");
        }
        i++;
    }

    let container = document.getElementById(elementID);

    if (container) {
        const response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);
        if (response) {
            if (reload !== undefined) {
                $('.content_commandes').html(response);
            } else {
                $(container).html(response);
            }
        } else {
            container.innerHTML = '';
        }
    }
}

// Used to add a store to favorites (store_details)
function addToMyFavorites() {
    let is_favorite_elem = document.getElementById('is_favorite');
    let self = $(this);
    const magasin_id = self.attr('data-magasinid');
    const action = self.attr('data-action');
    let act;

    // If the action is to destroy then we are in favorite_stores and we want to delete the entire block
    if (action == 'destroy') {
        act = 'del';
    } else {
        // We are in store_details and we must retrieve the current status of the store to know whether to delete or add it
        if (is_favorite_elem.value == '1') {
            act = 'del';
        } else {
            act = 'add';
        }
    }

    $.ajax({
        url: path_relative_root + 'ajax_manage_favorite_storelocator.php',
        type: 'post',
        dataType: 'html',
        data: 'act=' + act + '&magasin_id=' + magasin_id,
        success: function (response) {
            let libelle;
            if (response) {
                // Destruction du bloc
                if (action == 'destroy') {
                    self.slideUp('slow', function () {
                        self.remove();
                    });
                } else {
                    if (act == 'add') {
                        libelle = '<span class="choose_fav_label"><span class="bgSprite bfr store"></span><span>' + Translator.translate('delete_as_favorite_store') + '</span></span>';
                        $('.favorite').addClass('active');
                        self.addClass('active');
                        is_favorite_elem.value = 1;
                    } else {
                        libelle = '<span class="choose_fav_label selected"><span class="bgSprite bfr store"></span><span>' + Translator.translate('choose_as_favorite_store') + '</span></span>';
                        $('.favorite').removeClass('active');
                        self.removeClass('active');
                        is_favorite_elem.value = 0;
                    }
                    self.html(libelle);
                }
            }
        }
    });
}

// Change cart product quantity
function changeQte(type) {
    const types = ['plus', 'less'];
    let item, loader, qtty, prodId;

    if (types.includes(type)) {
        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);
        prodId = this.getValue('item_prod_id', parseFloat);

        $(this).find('.selector').hide();
        loader.style.display = 'block';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link('order_basket'),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {
                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();

                wShop.$refs.wCouponForm.reloadBloc += 1;
                //update top cart
                let response = ajax_file("ajax_show_basket.php");

                cart = Translator.translate('mon_panier');

                document.getElementById('cart_top').innerHTML = response;

                let total = $('#cart_top .cart').attr('data-cart');
                let text;

                if (total <= 1) {
                    text = Translator.translate('one_product');
                } else {
                    text = Translator.translate('2_product');
                }

                $('.cart_main_title.basket').html(cart + ' ' + '<span class="nb_products">(' + total + ' ' + text + ')</span>');
            },
            complete: function () {
                dragDropWishlist();
                wording_faq_cart();

                if (typeof dataLayer_v3 !== 'undefined' && typeof dl_v3_impressions !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && type == 'less') {
                    DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, prodId, "remove_from_cart");
                }

                if (typeof dataLayer_v3 !== 'undefined' && typeof dl_v3_impressions !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && type == 'plus') {
                    DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, prodId, "add_to_cart");
                }

                if ($(".push_container.pushBasketWrapper").length) {
                    processPushBasket();
                }
            }
        });
    }
}

// Preco lightbox cart delivery
function process_type_preco() {
    let val = $(".bloc_lightbox_preco.actif").attr("data-value");

    $.ajax({
        type: "post",
        data: {type_preco: val},
        url: path_relative_root + "ajax_choose_preco.php",
        success: function (response) {
            if (response.status == "success") {
                if ($("input[name=preco_choice]").length == 0) {
                    closeMultiShad("lightbox_preco");
                }

                let index_conveyor = typeof array_transporteurs_datas[1] != "undefined" ? 1 : 31;

                $.each(response.transportation, function (i, item) {
                    if (typeof array_transporteurs_datas[i] !== "undefined") {
                        array_transporteurs_datas[i]["fdp"][0] = item["unit"];
                        array_transporteurs_datas[i]["fdp"][1] = item["cent"];
                        array_transporteurs_datas[i]["fdp"]["format"] = item["format"];
                        $(
                            ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .pricetag"
                        ).html(item["format"]);
                        $(
                            ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .price_tag"
                        ).html(item["format"]);
                    }
                });

                if (val == "several") {
                    $(".choix_delivery_relais").hide();
                    $(".choix_delivery_postal:first .choix_delivery").click();
                    $(".choix_delivery_postal:first .choix_livraison").attr("checked", "checked");
                    $(".choix_delivery_postal:first .choix_livraison").change();
                } else {
                    $(".choix_delivery_relais").show();
                    $(".choix_delivery_relais:first").click();
                    $(".choix_delivery_relais:first .choix_livraison").attr("checked", "checked");
                    $(".choix_delivery_relais:first .choix_livraison").change();
                }

                $("#tunnel_right_col #cart_total").css("visibility", "visible");
            }
        },
    });
}

function showQteListe(elementID, idProduit, parentPath, suff) {
    let couleurs_id = 'couleurProd';
    let tailles_id = 'tailleProd';

    if (elementID != 'liste_qte') {
        couleurs_id += '_' + idProduit;
        tailles_id += '_' + idProduit;
    }

    let listQte = $('#' + elementID);
    let alternative_listQte = $('#liste_qte_spe');
    let couleurs, tailles;

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html('<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' + parentPath + 'img/loader.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>');

            couleurs = (($('#' + couleurs_id).length) ? $('#' + couleurs_id).val() : 'none');
            tailles = (($('#' + tailles_id).length) && ($('#' + tailles_id).val() != 0) ? $('#' + tailles_id).val() : 'none');
        } else {
            alternative_listQte.html(listQte.html());
            couleurs = 'none';
            tailles = 'none';
        }

        let response = ajax_file(parentPath + 'ajax_liste_qte.php?idprod=' + idProduit + '&idcouleur=' + couleurs + '&idtaille=' + tailles + '&produit_lot_nb=' + $("#produit_lot_nb").val() + '&suff=' + (suff || ''));

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            $('.selectStyled').each(function () {
                $(this).selectStyled();
            });

            let select_stock = $('#qteProd');
            let regexp_epuise = /Stock /;
            let txt_stock = $('#qteProd>option:selected').text();

            if (select_stock.length > 0 && txt_stock.match(regexp_epuise) != null) {

                $("#bloc_add_alert").show();
                $("#add_basket").hide();
                $("#bloc_add_basket").hide();
                $(".eclat_epuise").removeClass('cache');
                $(".eclat_epuise").show();
                $(".eclatProd").hide();

            } else {

                $("#bloc_add_alert").hide();
                $("#add_basket").show();
                $("#bloc_add_basket").show();
                $(".eclat_epuise").hide();
                $(".eclatProd").show();

            }

        }

    }
}

function showQteListeGamme(elementID, idProduit, parentPath) {
    let listQte = document.getElementById(elementID);
    let alternative_listQte = document.getElementById('liste_qte_spe_' + idProduit);

    let couleurs, tailles;

    if (listQte || alternative_listQte) {
        if (listQte) {
            if (document.getElementById('couleurProd_' + idProduit)) couleurs = document.getElementById('couleurProd_' + idProduit).value;
            if (document.getElementById('tailleProd_' + idProduit)) tailles = document.getElementById('tailleProd_' + idProduit).value;
            listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/loader.gif" />Chargement...</td></tr></table>';
        } else {
            couleurs = 'none';
            tailles = 'none';
            alternative_listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/loader.gif" />Chargement...</td></tr></table>';
        }

        let string_params = '';
        if ($("#produit_lot_nb_" + idProduit).val() != undefined)
            string_params += '&produit_lot_nb=' + $("#produit_lot_nb_" + idProduit).val();

        if ($("#qte_default_" + idProduit).val() != undefined)
            string_params += '&qte_default=' + $("#qte_default_" + idProduit).val();

        let response = ajax_file(parentPath + 'ajax_liste_qte_alerte.php?idprod=' + idProduit + '&idcouleur=' + couleurs + '&idtaille=' + tailles + string_params);

        if (response) {
            if (listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;

            $('.selectStyled').each(function () {
                $(this).selectStyled();
            });
        }
    }
}

function buildItemAddedModbox(id_produit, is_not_produit) {
    let form = document.getElementById('prod_info_' + id_produit);
    let closing_method = (is_not_produit == 'true') ? 'window.location.reload()' : 'close_lightbox(\'modbox\')';
    let lot_nb = form.getValue('produit_lot_nb', parseFloat);
    let itm_vis, itm_feat, itm_length;
    let itm_name = $('#itm_name', form).get(0).textContent.trim();
    let itm_price = $("#prixU", form).val();

    if (document.getElementById('nbr_visu_0')) {
        itm_vis = $('img', '#nbr_visu_0').attr('src').replace('/mini/', '/large/');
    }

    if ($('#itm_feat', form).length > 0) {
        itm_feat = $('#itm_feat', form).get(0).textContent.trim();
    }

    if (lot_nb > 1) {
        itm_price = formatPrice(parseFloat(itm_price) * parseInt(lot_nb));
    }

    if (form) {
        itm_length = parseFloat(form.elements.namedItem('qteProd').value);

        if (lot_nb > 2 || itm_length > 1) {
            itm_length += ' ' + Translator.translate('items');
        } else {
            itm_length += ' ' + Translator.translate('item');
        }

        if (itm_length == 1) {
            itm_length += ' ' + Translator.translate('added_to_cart_sn');
        } else if (form.getValue('produit_lot_nb') == '1') {
            itm_length += ' ' + Translator.translate('added_to_cart_pl');
        }

    }

    $('#shad').after($('#modbox'));

    document.getElementById('modbox').innerHTML = Mustache.render(addToCartTmpl, {
        closing_method: closing_method,
        itm_vis: itm_vis,
        itm_name: itm_name,
        itm_feat: itm_feat,
        itm_price: itm_price,
        itm_length: itm_length
    });
}

function newshowmodal(id_produit, is_not_produit) {
    CartShow();
}

function waitingAddToBasket_legacy(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, is_lightbox_specifique) {
    let id = '';
    if (is_not_produit == true)
        id += '_' + produit_id;

    if (is_lightbox_specifique)
        add_produit_specifique(produit_id, idCouleur, idTaille);
    else
        window.setTimeout('addToBasket_legacy("' + produit_id + '", "' + suff + '", "' + panier_id + '", "' + titreObjet + '", "' + idTaille + '", "' + lastTaille + '", "' + idCouleur + '", "' + lastCouleur + '","' + toEval + '", "' + page_name + '", "' + path_web + '", ' + enable_omniture + ', ' + is_not_produit + ');', 1000);

    $("#bloc_btn_active" + id).attr('class', 'cache');
    $("#bloc_btn_loader" + id).attr('class', 'f_right');

}

function addToBasket_legacy(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit) {
    var id = '';

    if (is_not_produit == true)
        id += '_' + produit_id;

    prix_id = 'prixU';
    if (is_not_produit == true) {
        var code_color = $('#couleurUnique_' + produit_id).text();
        var nom_color = $('#couleurUnique_' + produit_id).text();
        var prix_id = 'prixU_' + produit_id;
    }

    var array_panier = newUpdatePanier_legacy(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false) {
        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {
            type = 'err';
        }

        omnitureEvent = '';
        var response = ajax_file(path_web + 'ajax_show_basket.php');
        $("#cart_top").html(response);
        CartShow();

        if (array_panier[1] === 0) {
            $('#nb_article').html(array_panier[1]);
            omnitureEvent = '';
        } else if (array_panier[1] == 1) {
            $('#nb_article').html(array_panier[1]);
            $('#panier').attr('href', path_web + create_link('order_basket')).attr('style', 'cursor:pointer;');

            omnitureEvent = 'scOpen,scAdd'; // The first product added to cart
        } else {
            $('#nb_article').html(array_panier[1]);
            $('#panier').attr('href', path_web + create_link('order_basket')).attr('style', 'cursor:pointer;');

            omnitureEvent = 'scAdd'; // There is already a product in cart
        }

        newshowmodal(array_panier[1], type, path_web, is_not_produit, produit_id);

        $("#bloc_btn_active" + id).attr('class', '');
        $("#bloc_btn_loader" + id).attr('class', 'f_right cache');

        if (array_panier[1] > 0 && enable_omniture == true) {
            void (s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void (s.t());
        }
    } else {
        $("#bloc_btn_active" + id).attr('class', '');
        $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
    }

    eval(toEval);

    if (array_panier != false) {
        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {
            var svn = document.getElementsByTagName("SELECT");
            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function newUpdatePanier_legacy(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur) {
    if (i != '') {
        i = '_' + i;
        prixU = $('#prixU' + i).val();
    }

    let couleurs = document.getElementById('couleurProd' + i);
    let tailles = document.getElementById('tailleProd' + i);
    let alertbox = $('#alertbox1');
    let res = '';
    let html_err = '';
    let flagError = false;

    html_err = '<div class="txt_alert"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="button primary">OK</a></div>';
    if (alertbox.length == 0) {
        alertbox = $('<div id="alertbox1" class="lightbox">' + html_err + '</div>');
        $('body').append(alertbox.hide());
    }

    if (tailles && tailles.value == '') {
        res = Translator.translate('js_error_size');
        flagError = true;

    } else if (couleurs && couleurs.value == '') {
        res = Translator.translate('js_error_couleur');
        flagError = true;

    } else if (document.getElementById('qteProd' + i) && document.getElementById('qteProd' + i).value == '') {
        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        alertbox.find('.txt_alert').html(res);
        openMultiShad('alertbox1', 'fixed');
        return false;
    } else {
        let qte = 0;

        if ($('#qteProd' + i)) {
            qte = $('#qteProd' + i).val();
        }

        let idCouleur = 0;
        let idTaille = 0;

        if (couleurs && couleurs.value != 'none') idCouleur = couleurs.value;
        if (tailles && tailles.value != 'none') idTaille = tailles.value;

        let array_response = new Array();
        let response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);

        if (response) {
            array_response = response.split("//");

            let designation = array_response[0];

            if (titreObjet.match('^[0-9]')) {
                if (designation == 1) {
                    designation += ' lot de';
                } else {
                    designation += ' lots de';
                }
            }

            designation += ' ' + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    //designation += ' - ' + document.getElementById(idElCouleur).text; // Legacy - not working
                    designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;
                } else if (lastTaille != '') {
                    designation += ' - Taille ' + lastTaille;
                }
            }

            if (document.getElementById(idElCouleur)) {
                if (document.getElementById(idElCouleur).selectedIndex) {
                    //designation += ' - ' + document.getElementById(idElCouleur).text; // Legacy - not working
                    designation += ' - ' + document.getElementById(idElCouleur).options[document.getElementById(idElCouleur).selectedIndex].text;
                } else if (lastCouleur != '') {
                    designation += ' - ' + lastCouleur;
                }
            }

            array_response[0] = designation;
        }

        return array_response;
    }
}

function verif_telephone(elementID, fieldname, is_mobile) {
    // type 0 -> fixe (01)
    // type 1 -> mobile (06)
    // type 3 -> fixe (08)
    // type 6 -> fixe (09)
    // Le plugin refuse les 07, donc si il s'agit d'un 07, on ne passe pas par le plugin ( intlTelInput("isValidNumber") ).

    let objElementHidden = $('#' + elementID + 'Hidden');
    let objElement = $('#' + elementID);
    let inputMobile = '';
    let inputTel = '';

    if ($('#mobileHidden').length > 0) { //tunnel
        inputMobile = $('#telephoneHidden');
        inputTel = $('#mobileHidden');
    } else if ($('#clientMobileHidden').length > 0) { //mes info
        inputMobile = $('#clientMobileHidden');
        inputTel = $('#clientTelHidden');
    } else { // newsletter
        inputMobile = inputTel = objElementHidden;
    }

    let value = objElement.intlTelInput("getNumber");
    let type = objElement.intlTelInput("getNumberType");

    if ($.trim(objElement.val()).length > 0) {
        if (value.length == 12 && value.substr(1, 3) == '337') {
            inputMobile.val(value);
        } else {
            if (objElement.intlTelInput("isValidNumber") !== false && (type === 0 || type === 3 || type === 6)) {
                inputTel.val(value);
            } else if (objElement.intlTelInput("isValidNumber") !== false && type === 1) {
                inputMobile.val(value);
            } else if (objElement.intlTelInput("isValidNumber") !== false && type === 2) {
                inputTel.val(value);
                inputMobile.val(value);
            } else {
                objElement.addClass('inputErr');
                return false;
            }
        }
        return true
    } else {
        objElement.addClass('inputErr');
        return false;
    }
}

// Alert stock send email
function sendMailAlertStock(type_alert, form, itm_rr_id) {
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    let id = form.getValue('produit_id');
    let is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    let elem = is_achat_express ? '_' + id + itm_rr_id : '';

    let $email = $('#mail_alerte_stock' + elem).val();
    let $produit_id = $('#produit_principal' + elem).val();
    let $taille_id = $("#tailleProd" + elem).val();
    let $couleur_id = $("#couleurProd" + elem).val();
    let $type_alert = type_alert;

    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let mail_value = $('#mail_alerte_stock' + elem).val();
    let inputEmailBis = document.getElementsByClassName('expressAlertStock');

    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {
        $('#mail_alerte_stock' + elem).addClass('inputErr');
        $(inputEmailBis).addClass('inputErr1');
    } else {
        $('#mail_alerte_stock' + elem).removeClass('inputErr');
        $(inputEmailBis).removeClass('inputErr1');

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function (response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
            },
            fail: function () {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

// Called upon size change in product page
function onItemSizeChange(form, is_regroup, itm_rr_id = '') {
    let checkForm = $(form).attr('id');
    let isWishList = $('#wishlist').length > 0;
    let isAchatExpressV2 = (form.getValue('isAchatExpressV2') == 1 && !isWishList);
    let productId = (checkForm === 'formAchatExpressV1') ? $(form).attr('data-produitid') : form.getValue('produit_id');
    let colorId = form.getValue('couleurProd');
    itm_rr_id = (itm_rr_id !== undefined && itm_rr_id !== null) ? itm_rr_id : '';
    let sizeId = this.value.trim();

    $(this).parents('.wrap_rolloverproduit').addClass('size_selected');
    $(this).parents('.wrap_rolloverproduit').find('.bloc_add_color').show();
    $(this).parents('form').find('.bloc_add_alert_confirmation').slideUp('slow');

    if ($("body").hasClass("product_page") && $(form).hasClass("product_info")) {
        $(this).parents('form').find('.bloc_add_alert_form').slideUp('slow');
    }

    if (isAchatExpressV2) {
        if (this.checked) {
            if ($('#sizeerror').is(':visible')) {
                $('#sizeerror').slideUp();
            }

            getAvailableQuantity(productId, 1, 'taille', colorId, itm_rr_id);

            // Depending on the color we see if the product is available, do this only in product page
            // We check if the product is already in the wishlist
            checkProductInWishlist(productId, itm_rr_id);

            if ($(".tag_button.actif").length ) {
                $('.bloc_add_color').removeClass('inactiv');
                $('.disabled_whislist, .no_size_selected').remove();
            }
        }

    } else {
        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        $('#tailleProd').val(sizeId);
        if (is_regroup) {
            var newProdId = $(this).data('prod');
            $("#btn_add_cart").prop('disabled', true);
            loadProduct.call(form, newProdId, 'taille', 'product', itm_rr_id, 'sizeChange');
        } else {
            getAvailableQuantity(productId, 1, 'taille', colorId, itm_rr_id, sizeId);
            checkProductInWishlist(productId, itm_rr_id);
        }
    }
}

// Display lightbox contact store
function showLightboxContactStore() {
    const shad = document.getElementById('shad');
    const modbox = document.getElementById('pdvBox');

    $('html, body').animate({
        scrollTop: 0
    }, 500);

    $(modbox).addClass('actif');
    $(shad).addClass('actif');
    $(shad).on('click', closeLightboxContactStore);
    $('#content_pdvBox').show();

    setTimeout(function () {
        $('#numTelephone').intlTelInput();
    }, 400);
}

// Close lightbox contact store
function closeLightboxContactStore() {
    const shad = document.getElementById('shad');
    const modbox = document.getElementById('pdvBox');

    $(modbox).removeClass('actif');
    $(shad).removeClass('actif');
}

// Send request contact to store
function sendFormContactByStore(formulaire) {
    const optin_g = ($("input[name='optin']:checked").val());
    const optin_sms = ($("input[name='optin_sms']:checked").val());
    const optin_partners = ($("input[name='optin_partners']:checked").val());

    const data = {
        'produit_id': formulaire.produit_id.value,
        'produit_url': formulaire.produit_url.value,
        'client_nom': formulaire.nom.value,
        'client_prenom': formulaire.prenom.value,
        'client_email': formulaire.email.value,
        'client_telephone': formulaire.tel.value,
        'client_message': formulaire.message.value,
        'magasin_id': formulaire.magasin_id.value,
        'optin_general': optin_g,
        'optin_sms': optin_sms,
        'optin_partners': optin_partners
    };

    $.ajax({
        url: path_relative_root + create_link('ajax_contactbystore'),
        type: 'post',
        data: data,
        async: false,
        success: function (res) {
            $('#content_pdvBox_new').html(JSON.parse(res));
            $('#pdvBox').addClass('thanks');
        }
    });
    return false; //prevent submit
}

function changeVisu(field, path) {
    let new_img;

    field = document.getElementById(field);

    if (field && field.parentElement) {
        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener('load', function () {
            this.alt = field.alt;

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.onclick = function () {
            zoomImg();
        }

        new_img.src = path;
        $("#zoom_box_wrapper #img_large").attr("src", path);
    }

    let productSwiper = new Swiper(".productSwiper:not(.w-slider-container)", {});
}

function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        let sizes_tab = window.json_colisages[$(this).val()];
        let size_box, is_disabled;
        let has_stock = false;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('[name="itm_size"]').filter('[value="' + size_id + '"]');
                is_disabled = !sizes_tab[size_id];
                if (!is_disabled) {
                    size_box.removeClass('disabled');
                    has_stock = true;
                } else {
                    size_box.addClass('disabled');
                }
            }
        }

        if (has_stock) {
            $("#btn_add_cart").show();
            $('.eclat_nouveaute').hide();
            $(".eclat_epuise").hide();
        } else {
            if ($('#case_ajax') !== 'undefined') {
                if ($('#case_ajax').val() == '5' || $('#case_ajax').val() == '6' || $('#case_ajax').val() == '7') {
                    $(".eclat_nouveaute").removeClass('cache').show();
                    $(".eclat_epuise").hide();
                    $(".eclatProd").show().removeClass('cache');

                    $("#btn_alert_stock").removeClass('cache').show();
                    $("#btn_add_cart").hide();
                } else if ($('#case_ajax').val() == '8') {
                    $(".eclat_epuise").removeClass('cache').show();
                    $(".eclat_nouveaute").hide();
                    $(".eclatProd").hide();

                    $("#btn_add_cart").hide();
                    $("#btn_alert_stock").hide();
                } else { // Case 1 / 2 / 3 / 4
                    $(".eclat_epuise").hide();
                    $(".eclat_nouveaute").hide();
                    $(".eclatProd").show().removeClass('cache');

                    $("#btn_add_cart").removeClass('cache').show();
                    $("#btn_alert_stock").hide();
                }
            }
        }
    }
}

function changeLocale(locale, uri, site) {
    $.ajax({
        url: path_relative_root + 'ajax_get_uri_by_lang.php',
        type: 'post',
        data: {
            lang: locale,
            url: uri,
            site: site
        },
        success: function (data) {
            if (data != "") {
                let force = "/";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        }
    });
}

// for the size guide tables
function fadeTaille(id, nbelem) {
    for (let i = 1; i <= nbelem; i++) {
        if (id != i && $("#content_quide_" + i).css('display') == 'block') {
            $("#content_quide_" + i).slideUp(400).removeClass('actif');
            $("#intitule_quide_" + i).removeClass('actif');
        }
    }

    if ($("#content_quide_" + id).css('display') != 'block') {
        $("#content_quide_" + id).slideDown('slow');
        $("#content_quide_" + id).addClass('actif');
        $("#intitule_quide_" + id).addClass('actif');
    } else {
        $("#content_quide_" + id).slideUp(400);
        $("#content_quide_" + id).removeClass('actif');
        $("#intitule_quide_" + id).removeClass('actif');
    }
}

// init tg module function
function tgModuleInit() {
    // TG homepage
    if($('.home-module-body.tg-module').length) {
        $('.home-module-body.tg-module').on('mouseenter touchstart', function () {
            $(this).find('.home-module-title').click(function() {
                var module_tg_id = $(this).closest(".tg-module").attr('id');
                var tg_id = $(this).attr('id');
                var tg_title_id = tg_id.replace('-title', '');
                $("#" + module_tg_id + " .home-module-title, #" + module_tg_id + " .home-module-tg").removeClass("selected");
                $("#" + tg_id).addClass("selected");
                $("#" + tg_title_id).addClass("selected");
            });

            $(this).find(".home-module-tg.swiper_tdg_home").each(function () {
                if ($(this).find(".swiper-slide").length > 3 && (!$(this).hasClass("swiper-initialized"))) {
                    var home_image_image_image_module = new Swiper(this, {
                        slidesPerView: 5,
                        spaceBetween: 5,
                        navigation: {
                            nextEl: ".tg_button_wrapper .swiper-button-next",
                            prevEl: ".tg_button_wrapper .swiper-button-prev",
                        },
                        scrollbar: {
                            el: ".swiper_tdg_home .swiper-pagination",
                            type: "progressbar",
                            draggable: true,
                        },
                        breakpoints: {
                            640: {
                            slidesPerView: 2.8,
                            spaceBetween: 5
                            },
                            1160: {
                                slidesPerView: 4,
                                spaceBetween: 5,
                            },
                            1450: {
                                slidesPerView: 5,
                                spaceBetween: 5,
                            },
                        },
                    });
                }
            });
        });
    }

    if($('.cms-page-module-body.tg-module').length) {
        $('.cms-page-module-body.tg-module').on('mouseenter touchstart', function () {
            $(this).find('.cms-page-module-title').click(function() {
                var module_tg_id = $(this).closest(".tg-module").attr('id');
                var tg_id = $(this).attr('id');
                var tg_title_id = tg_id.replace('-title', '');
                $("#" + module_tg_id + " .cms-page-module-title, #" + module_tg_id + " .cms-page-module-tg").removeClass("selected");
                $("#" + tg_id).addClass("selected");
                $("#" + tg_title_id).addClass("selected");
            });

            $(this).find(".cms-page-module-tg.swiper_tdg_adv").each(function () {
                if ($(this).find(".swiper-slide").length > 3 && (!$(this).hasClass("swiper-initialized"))) {
                    var cms_tdg = new Swiper(this, {
                        slidesPerView: 5,
                        spaceBetween: 5,
                        scrollbar: {
                            el: ".swiper_tdg_adv .swiper-pagination",
                            type: "progressbar",
                            draggable: true,
                        },
                        breakpoints: {
                            640: {
                            slidesPerView: 2.8,
                            spaceBetween: 5
                            },
                            1160: {
                                slidesPerView: 4,
                                spaceBetween: 5,
                            },
                            1450: {
                                slidesPerView: 5,
                                spaceBetween: 5,
                            },
                        },
                    });
                }
            });
        });
    }
}
// Back to top event
function backToTopShow () {
    const scrollTrigger = 200;
    const scrollTop = $(window).scrollTop();

    if (scrollTop > scrollTrigger && !isInViewport('#copyright')) {
        $('.back_to_top').addClass('show');
    }
    if (isInViewport('#copyright') || scrollTop <= scrollTrigger) {
        $('.back_to_top').removeClass('show');
    }
}

function toggleGiftCardBalance() {
    $(".pageGiftCard__main--amountinfos").slideToggle().toggleClass('is-hidden');
    $(this).find('.showAmountBtn').toggleClass('open');
}

function triggerDropdown(elem, trigger, toDrop) {
    if (!$(elem).hasClass("actif")) {
        $(elem).addClass('actif');
        $(elem).next(toDrop).addClass('actif').slideDown();
    } else {
        $(elem).removeClass('actif');
        $(elem).next(toDrop).removeClass('actif').slideUp();
    }
}

function showNumRetourEtiquette(idOrder, multiTransp, parentPath, idOrderTransp, idTransporteur) {
    // globally define the carrier id to use it in the creation of the label
    global_id_transporteur = idTransporteur;

    let divId = 'popup_numero_retour';
    divId += '_' + (multiTransp ? idOrderTransp : idOrder);
    let formId = '#return_products_form';
    formId += '_' + (multiTransp ? idOrderTransp : idOrder);

    openMultiShad(divId);
    $('#shad').addClass('actif for_gdt');
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp) {
    let idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour + '&send_mail=true&choix=no_impression';
    } else {
        data = 'idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;
    }

    $.ajax({
        url : path_relative_root + "ajax_create_etiquette.php",
        type : 'post',
        data : data,
        success : function (res) {
            if (res.substr(0,5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    let response = ajax_file(path_relative_root + 'ajax_return_address.php?order_id=' + idCmdOrder + '&return_number=' + numRetour);

                    if (response !== '') {
                        $('.popup_numero_retour').html(response);
                        // add a title on return & return coupon popup
                        $("<div class='return_popup_title'>" + Translator.translate('return_pop_title') + "</div>").insertBefore("#printPdf");
                    } else {
                        location.reload();
                    }
                }
            }
        }
    });
}

// Express purchase used for lookbooks (on tags)
function ajax_achat_express(reference, element) {
    $.ajax({
        type: "get",
        url: path_relative_root + "ajax_achat_express.php?ref=" + reference,
        success: function(response) {
            $("#content_achat_express").html(response);

            openMultiShad("lightbox_achat_express");
            $('#shad').addClass('for_gdt');
            $('body').addClass('fixed');

            // Event on the add to wishlist button
            $("#addToWishlistButton").on("click", addToWishlist);

            if (!$(".w-alertestock-form").length) {
                new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
            }

            // For the tooltip events "Please select a size"
            eventSizeButton();

            if ($("#buyNowSwiper").length > 0) {
                let buyNowSwiper = document.getElementById("buyNowSwiper");
                if (buyNowSwiper.getElementsByClassName("swiper-slide").length) {
                    let buyNowSwiper = new Swiper("#buyNowSwiper", {
                        sliderPerView: 1,
                        spaceBetween: 50,
                        nextButton: '.swiper-button-next',
                        prevButton: '.swiper-button-prev'
                    });
                }
            }

            /* Remove onclick zoom on slider */
            $('#lightbox_achat_express .mainSlideFp img').removeAttr('onclick');

            if ($('#lightbox_achat_express .mainSlideFp').length) {
                new Swiper('#lightbox_achat_express .mainSlideFp', {
                    slidesPerView: 1,
                    navigation: {
                        nextEl: '.mainSlideFp_mini_achat_express.swiper-button-next',
                        prevEl: '.mainSlideFp_mini_achat_express.swiper-button-prev',
                    },
                });
            }

            setTimeout(function () {
                checkSubmitState();
                checkSubmitStateProduct();

                $(".productSizeFieldset .prod_listes_sizes").on("click touch", function () {
                    $(".sizesList").toggleClass("opened");
                });

                if ($('.primary_navbar').length && !$('.nav_bar_frozen').length) {
                    $('.navbar_tab').click(function () {
                        let tab = $(this).data('tab');
                        // Identify navbar to handle multiple primary_navbar occurences
                        let navbar = $(this).parent().attr('data-navbar');

                        /* change nav active on this elem **/
                        $(this).parent().find($('.navbar_tab')).removeClass('active');
                        $(this).addClass("active");

                        /* If text should be present **/
                        if (!$('.no_text').length) {
                            $(".navbar_cnt[data-navbar='" + navbar +"']").removeClass('actif');
                            $(".navbar_cnt[data-nav='" + tab + "'][data-navbar='" + navbar +"']").addClass('actif');
                        }

                        /* If nav tabs have specific content to show **/
                        if ($('.tab_content').length) {
                            $(".tab_content[data-navbar='" + navbar +"']").removeClass('actif')
                            $(".tab_content[data-tab='" + tab + "'][data-navbar='" + navbar +"']").addClass("actif");
                        }
                    });
                }
            }, 500);
        },
    });
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {
    let numRetour = "";

    if (typeof idOrderTransp != "undefined")
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp +
            "&idOrderTransp=" +
            idOrderTransp
        );
    else
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp
        );


    if (numRetour != "false") {
        let transporteur = '';

        // In case of multiple carriers we define the block that should be displayed
        if (multiTransp == 1) {
            transporteur = '_' + global_transporteur_id;
        }

        let commentaireRetourObjet = "";

        if (document.getElementById("commentaireRetourDiv" + transporteur))
            commentaireRetourObjet = document.getElementById("commentaireRetourDiv").value;

        if (multiTransp !== 1) {
            if (document.getElementById("commentaireRetour") !== null) {
                commentaireRetourObjet = document.getElementById("commentaireRetour").value;
            }
        }

        let recommand = $(".recommandation").length;

        let has_errors = false;
        $(".return_quantity").each(function () {
            let return_choice = $(this).closest('.js-select-motif').find('.return_choice');
            return_choice.removeClass('error');

            if ($(this).val() > 0 && return_choice.val() == '0') {
                has_errors = true;
                return_choice.addClass('error');
                /* Put text message when not selected an option*/
                $(".motif_dropdown .date_select").append('<div class="error_message">' + Translator.translate('messageerror') + '</div>');
            } else {
                return_choice.removeClass('error');
            }
        });

        if (!has_errors) {
            if (recommand) {
                $(".recommandation p span").css("color", "#000");
                $('p[id^="liste_motif_retour"] .select').css("border", "none");
                $("#divBtnPrintPdf").show();
                $('.popup_numero_retour .loader').addClass('loading');

                setTimeout(function () {
                    $('.popup_numero_retour .loader').removeClass('loading');
                }, 3500);
            }
            showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, false, idOrderTransp);
        } else {
            if (recommand) {
                $(".recommandation p span").css("color", "#a40000");
                $('p[id^="liste_motif_retour"] .select').css("border", "2px solid #a40000");
            }
        }
    }
}

// Close 2nd return popup after ajax loaded
function close_popup_return(obj_elem) {
    $("#shad").hide();
    $("#popup_numero_retour_" + obj_elem).hide();
    location.reload();
}

function slideSizeguideBlock() {
    $('#sizeguideBox').addClass('actif');
    $('#shad').addClass('for_gdt');
}

function CartShow() {
    setTimeout(function () {
        $("#shad").addClass("actif");
        $("#site_head_wrap").addClass("prod_add_to_cart");
        $('#show_top_cart').addClass('open');
    }, 500);

    if ($('.item_product.actif').length) {
        $('.item_product').removeClass('actif');
    }

    if ($('.tag_button.actif').length) {
        $('.tag_button').removeClass('actif');
    }

    setTimeout(function () {
       $("#show_top_cart").removeClass("open");
       $("#shad").removeClass("actif");
       $("#site_head_wrap").removeClass("prod_add_to_cart");
    }, 2500);
}

function sticky_bar_fp() {
    setTimeout(function () {
        let product_info_block = $('.pos_sticky > .product_info');
        let product_info_blockH = product_info_block.outerHeight();
        let reassurance_wrapper = $('.pos_sticky .reassurance_wrapper');
        let body_has_sticky_fp = $('body.product_page');
        let product_info_sticky_right = $('.product_info_wrapper .pos_sticky');
        let site_head_wrapH = $('#site_head_wrap').outerHeight();
        let breadcrumbsH = $('#breadcrumbs').outerHeight();
        let reassurance_wrapperZ;

        $(window).scroll(function () {
            if(!$('.has_sticky_fp').length) {
                product_info_blockH = product_info_block.outerHeight();
            }
            let windowpos = $(window).scrollTop();
            if (reassurance_wrapper.length) {
                reassurance_wrapperZ = reassurance_wrapper.offset().top;
                let diff_reassurance_wrapper = reassurance_wrapperZ - site_head_wrapH - breadcrumbsH;
                if (windowpos >= diff_reassurance_wrapper) {
                    product_info_block.addClass('sticky_bar_fp');
                    // prevent footer from overlapping sticky bar
                    $('.footer_container').css('padding-bottom', ($('.product_info.sticky_bar_fp').height()*2) + 'px');
                    body_has_sticky_fp.addClass('has_sticky_fp');
                    product_info_sticky_right.css('padding-top', product_info_blockH + 'px');
                    product_info_sticky_right.css('position','relative');
                } else {
                    product_info_block.removeClass('sticky_bar_fp');
                    $('.footer_container').css('padding-bottom', '0');
                    body_has_sticky_fp.removeClass('has_sticky_fp');
                    product_info_sticky_right.css('padding-top', 0);
                    product_info_sticky_right.css('position','sticky');
                }
            }
        });
    }, 1000);
}

// Create a marker and its tooltip
function creerMarqueur(point, nom, adresse, ville, cp, pays, horaire, telephone, email, site, image_mag, type_mag, cpt, magasin_id, url, distance, currently_open, horaire_today) {
    let img = path_relative_root + "img/maps/marker.png";
    let http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    let image = new google.maps.MarkerImage(img,
        // This marker is 20 pixels wide by 32 pixels tall.
        new google.maps.Size(25, 29),
        // The origin for this image is 0,0.
        new google.maps.Point(0, 0));

    // The anchor for this image is the base of the flagpole at 0,32.
    //new google.maps.Point(10, 26));
    let shadow = new google.maps.MarkerImage(path_relative_root + 'img/maps/marker_ombre.png',
        // The shadow image is larger in the horizontal dimension
        // while the position and offset are the same as for the main image.
        new google.maps.Size(22, 11),
        new google.maps.Point(0, 0),
        new google.maps.Point(10, 0));
    // Shapes define the clickable region of the icon.
    // The type defines an HTML <area> element 'poly' which
    // traces out a polygon as a series of X,Y points. The final
    // coordinate closes the poly by connecting to the first
    // coordinate.

    let marqueur = new google.maps.Marker({
        position: point,
        icon: image
    });

    marqueur.set("magasin_id", magasin_id);

    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    url = $.trim(url);
    currently_open = parseFloat(currently_open);

    let info_horaire_today = !horaire_today ? '' : horaire_today;
    let class_horaire_today = currently_open ? 'store_open' : 'store_closed';

    let ligneListe = '<div class="info_popup"><div class="name"><a href="' + url + '">' + nom + '</a></div>';

    ligneListe += '<div class="adresse">' + adresse.toLowerCase() + '<br />' + cp + ' ' + ville.charAt(0).toUpperCase() + ville.slice(1).toLowerCase() + ', ' + pays + '</div>';

    if ((info_horaire_today !== '' && currently_open) || (!currently_open && horaire_today)) {
        ligneListe += '<div class="horaire_today"><span class="puce ' + class_horaire_today + '"></span> ' + info_horaire_today + '</div>';
    }

    // Tooltip more infos & go to store link
    ligneListe += `<div class="store_localisation_container">
        <div class="btn_voir_magasin go_to_store"><a class="geolocalisation_link" target="_blank" href="https://maps.google.fr/maps?daddr=${point.lat()},${point.lng()}">${Translator.translate('go_to_store')}</a></div>
        <div class="btn_voir_magasin"><a href="${url}">${Translator.translate('voir_magasin')}</a></div>
        </div>`

    google.maps.event.addListener(marqueur, 'click', function () {
         // selected store coordinates
        let storePosition = new google.maps.LatLng(point.lat(), point.lng());
        // center map on marker
        maCarte.setCenter(storePosition);
        // map animation on marker switch
        maCarte.panTo(storePosition);

        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });

    markersArray.push(marqueur);

    return marqueur;
}

// Create a block corresponding to a marker on the side list
function creerLigneListe(cpt, id, nom, adresse, ville, cp, pays, currently_open, horaire_today, distance, marqueur, picture_filtre, magasinId, stock, stockStatus, stock_quantity, clientId, favorite, beContacted, flag_available, url, shop_filter, telephone, query, lat, lng) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement('li');
    li.id = id;
    li.dataset.lng = lng;
    li.dataset.lat = lat;

    li.className = 'elem-list-store';

    if (lat != "" && lng != "" && !$('body.product_page').length) {
        li.setAttribute("onclick", "geolocGoto('" + lat + "', '" + lng + "')");
    }

    if (favorite == "1") {
        choose_fav = Translator.translate('actual_favorite_store');
    } else {
        choose_fav = Translator.translate('choose_as_favorite_store');
    }

    /* Product stock infos */
    if (stock_quantity == 'undefined' || stock_quantity == '') {
        stock_status_msg = 'unknow_stock';
    } else {
        var in_stock = (stock_quantity >= 6 && stock_quantity <= 9999);
        var limited_stock = (stock_quantity >= 1 && stock_quantity <= 5);
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = '';

        if (in_stock) {
            stock_status_msg = 'in_stock';
        }
        if (limited_stock) {
            stock_status_msg = 'limited_stock';
        }
        if (not_in_stock) {
            stock_status_msg = 'not_in_stock';
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = 'to_be_confirmed_stock';
        }
        if (can_be_ordered_stock) {
            stock_status_msg = 'can_be_ordered_stock';
        }
    }

    var info_horaire_today = !horaire_today ? Translator.translate("store_closed") : horaire_today;
    var class_horaire_today = currently_open ? 'store_open' : 'store_closed';
    var ligneListe = '<div class="elem_list_contents">' + '<div class="nom_store">';

    if (distance) {
        ligneListe += '<h2 class="title">' + nom + ' <p class="distance"> ' + distance + 'km' + '</p></h2>';
    } else {
        ligneListe += '<h2 class="title">' + nom + '</h2>';
    }

    ligneListe += '</div>' + '<div class="content_store">'

    if ($('body.product_page').length) {
        ligneListe += `<div class="detail_store" onclick="location.href = '${path_relative_root + url}'">`
    } else {
        ligneListe += '<div class="detail_store">'
    }

    ligneListe += '<div class="col-1">' +
        '<p><span class="rue_store">' + adresse + '</span>' +
        '<span class="ville_store">' + cp + ' ' + ville.charAt(0).toUpperCase() + ville.slice(1).toLowerCase() + ' - ' + '<span class="pays_store">' + pays + '</span>' + '</span></p>';

    if (telephone != '' && telephone != 'undefined') {
        ligneListe += '<span class="tel_store">' + telephone + '</span></div>';
    }

    var is_page_product = $('body.product_page').length == 1;

    if ((info_horaire_today !== '' && currently_open) || (!currently_open && horaire_today)) {
        ligneListe += '<div class="col-2"><p class="horaire_today puce_' + class_horaire_today + '">' + info_horaire_today + '</p></div>';
    }

    ligneListe += '<a href="javascript:;" class="addfavorite" id="store' + magasinId + '" onclick="addToMyFavorite(\'' + magasinId + '\', \'' + clientId + '\', \'' + favorite + '\', \'' + escape(info_horaire_today) + '\',\'' + currently_open + '\',\'' + escape(horaire_today) + '\',\'' + class_horaire_today + '\',\'' + stock + '\',\'' + stockStatus + '\'); return false;">';
    ligneListe += '<span class="choose_fav_label" id="favShop-' + magasinId + '"><span>' + choose_fav + '</span></span></a>';

    ligneListe += '</div>';
    if (document.getElementById('produit_id')) {
        /* Product stock infos msg */
        if (stock != 'undefined') {
            ligneListe += '<p class="stock_status ' + stock_status_msg + '">' + Translator.translate(stock_status_msg + '_msg') + '</p>';
        }
    }

    if (is_page_product) {
        if (beContacted == 1) {
            if ($('body.product_page.mobile').length) {
                ligneListe += '<div class="availableTxt store_more" onclick="handleChangeMobile(' + id + ')">' + '<span>' + txt_available_mag + '</span>' + '</div>' + '</div>' + '</div>';
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe += '<div class="availableTxt store_more" onclick="handleChange(\'' + id + '\',\'' + nom_mag + '\')">' + '<span>' + txt_available_mag + '</span>' + '</div>' + '</div>' + '</div>';
            }
        } else {
            ligneListe += '</div>' + '</div>';
        }
    }

    if (!is_page_product) {
        ligneListe += '<div class="store_more"><a onclick="createDataLayer(' + id + ')" href="' + path_relative_root + url + '">' + Translator.translate('shop_info') + '</a>'
            + '</div>';
    }

    ligneListe += '</div>' + '</div>' + '</div>';

    li.innerHTML = ligneListe;

    li.addEventListener('click', function () {
        google.maps.event.trigger(marqueur, 'click');
    });

    return li;
}

// Tunnel FAQ
function fadePanier(id, nbelem) {
   for (var i = 1; i <= nbelem; i++) {
       if (id != i && $("#texte_question_" + i).css('display') == 'block') {
           $("#texte_question_" + i).slideUp(400);
           $("#intitule_question_" + i).removeClass('actif');
       }
   }

   if ($("#texte_question_" + id).css('display') != 'block') {
       $("#texte_question_" + id).slideDown(400);
       $("#texte_question_" + id).addClass('actif');
       $("#intitule_question_" + id).addClass('actif');
   } else {
       $("#texte_question_" + id).slideUp(400);
       $("#texte_question_" + id).removeClass('actif');
       $("#intitule_question_" + id).removeClass('actif');
   }
}

function updateCartElements() {
   if ($('#cartAssociations').length > 0) {
       let cartAssociations = new Swiper('#cartAssociations', {});
   }

   if ($('body.step_1').length) {
       getWishlistProducts();
       setTimeout(function () {
           let wishlist_title = $('.cart_main_title.wishlist');
           let cart_main_table_wishlist = $('.cart_main_table.wishlist');
           let cart_main_table_wishlist_products = $('.cart_main_table.wishlist .cart_product_line');
           let title_gondole_cart = $('.title_gondole');
           let basket_gondole_cart = $('.basket_gondole');
           let title_gondole_wrapper = $('.title_gondole_wrapper');

           /* Wishlist */
           if ((cart_main_table_wishlist_products.length) && (!$('.title_gondole_wrapper .cart_main_title.wishlist').length)) {
               wishlist_title.addClass('selected');
               wishlist_title.appendTo(title_gondole_wrapper);
               cart_main_table_wishlist.css('opacity','1');
           }

           $(".cart_product_modify .basketToWishlist span").on("click", function () {
               setTimeout(function () {
                   window.location.reload();
               }, 200);
           });

           if (!$('#site_global_wrap > .cart_main_table.wishlist').length) {
               if (cart_main_table_wishlist_products.length) {
                   cart_main_table_wishlist.appendTo('#site_global_wrap');
                   if (cart_main_table_wishlist_products.length > 2) {
                       new Swiper('.cart_main_table.wishlist', {
                           slidesPerView: 5,
                           spaceBetween: 5,
                           scrollbar: {
                               el: '.cart_main_table.wishlist .swiper-pagination',
                               type: "progressbar",
                               draggable: true,
                           },
                           breakpoints: {
                               640: {
                                 slidesPerView: 2.5,
                                 spaceBetween: 5
                               },
                               1030: {
                                   slidesPerView: 3,
                                   spaceBetween: 5,
                               },
                               1280: {
                                   slidesPerView: 4,
                                   spaceBetween: 5,
                               },
                               1450: {
                                   slidesPerView: 5,
                                   spaceBetween: 5,
                               },
                           },
                       });
                   }
               } else {
                   title_gondole_cart.addClass('selected');
                   basket_gondole_cart.show();
               }
           }

           /* Gondole panier */
           if ((basket_gondole_cart.length) && (!$('#site_global_wrap > .basket_gondole').length)) {
               basket_gondole_cart.appendTo('#site_global_wrap').css('opacity','1');
               basket_gondole_cart.find('.title_gondole').appendTo('.title_gondole_wrapper');
               $('.basket_gondole .swiper-slide.gondole').wrapAll('<div class="swiper swiper-container basket_gondole_swiper"><div class="swiper-wrapper"></div></div>');

               let basket_gondole_swiper = $('.basket_gondole_swiper');
               $( '<div class="swiper-scrollbar"></div>' ).appendTo( basket_gondole_swiper );

               if ($('.basket_gondole_swiper .swiper-slide').length > 2) {
                   new Swiper('.basket_gondole_swiper', {
                       slidesPerView: 5,
                       spaceBetween: 5,
                       scrollbar: {
                           el: '.basket_gondole .swiper-scrollbar',
                           type: "progressbar",
                           draggable: true,
                       },
                       breakpoints: {
                           640: {
                             slidesPerView: 2.5,
                             spaceBetween: 5
                           },
                           1030: {
                               slidesPerView: 3,
                               spaceBetween: 5,
                           },
                           1280: {
                               slidesPerView: 4,
                               spaceBetween: 5,
                           },
                           1450: {
                               slidesPerView: 5,
                               spaceBetween: 5,
                           },
                       },
                   });
               }
           }

           /* Switch Gondole */
           wishlist_title.on("click touch", function () {
               if (!wishlist_title.hasClass('selected')) {
                   wishlist_title.addClass('selected');
                   title_gondole_cart.removeClass('selected');
                   cart_main_table_wishlist.fadeIn('fast');
                   basket_gondole_cart.fadeOut('fast');
               }
           });

           title_gondole_cart.on("click touch", function () {
               if (!title_gondole_cart.hasClass('selected')) {
                   title_gondole_cart.addClass('selected');
                   wishlist_title.removeClass('selected');
                   basket_gondole_cart.fadeIn('fast');
                   cart_main_table_wishlist.fadeOut('fast');
               }
           });

           $(".cart_product_modify .remove_wish span").on("click touch", function () {
               setTimeout(function () {
                   if (wishlist_title.css('display') == 'none') {
                       title_gondole_cart.addClass('selected');
                       basket_gondole_cart.fadeIn('fast');
                   }
               }, 2000);
           });

       }, 1000);
   }

   /* TG naissance */
   if ($(".liste_naissance_slider_container ").length) {
       var liste_naissance_slider_container  = new Swiper(".liste_naissance_slider_container ", {
           loop: false,
           slidesPerView: 3,
           spaceBetween: 6,
           navigation: {
               nextEl: ".liste_naissance .tete_de_gondole-button-next",
               prevEl: ".liste_naissance .tete_de_gondole-button-prev",
           },
           scrollbar: {
               el: ".liste_naissance .swiper-pagination",
               type: "progressbar",
               draggable: true,
           },
       });
   }

   wording_faq_cart();
}

function cms_look_popup () {
   if ($(".cms-page-module-body .tag_button").length ) {
       $(".cms-page-module-body:not(.lookbook) .tag_button").each(function () {
           let this_wishlist_title_wrapper_cms = $(this).parent('.tag').find('.wishlist_title_wrapper');
           let this_wishlist_brand_wrapper_cms = $(this).parent('.tag').find('.wishlist_brand_wrapper');
           let this_data_id_item_product_cms = $(this).parent('.tag').find('.item.gondole').attr('id');
           let item_product_lightbox = $(this).next(".item_product");
           let size_list_selected = false;
           let size_not_selected_txt = $('<span class="no_size_selected">' + Translator.translate('choose_size_fp_disabled_btn') + '</span>');
           let block_disabled_whislist = $('<div class="disabled_whislist"></div>');

           if ($(this).parent('.tag').find('.productVisualMulti_look .wrap_rolloverproduit').length ) {
               $(this).parent('.tag').find('.productVisualMulti_look .wrap_rolloverproduit').appendTo(this_wishlist_title_wrapper_cms);
               $(this).parent('.tag').find('.wishlistBtn').appendTo(this_wishlist_brand_wrapper_cms);
           }

           if (!$(this).hasClass("actif")) {
               $(this).on("click", function () {
                   $(this).addClass('actif');
                   $(this).parents('.tag').find('.item.gondole').addClass('actif');
                   openMultiShad(this_data_id_item_product_cms);
               });
           }

           item_product_lightbox.each(function() {
               if ($(this).find(".productSizeFieldset .form_itm.size").length > 1 ) {
                   if ($(this).find(".prodSize").is(":checked")) {
                       size_list_selected = true;
                       return false;
                   }

                   if (!size_list_selected) {
                       item_product_lightbox.find(".bloc_add_color").addClass("inactiv");
                       item_product_lightbox.find(".productSizeFieldset").append(size_not_selected_txt.hide());
                       item_product_lightbox.find(".achat_express").append(block_disabled_whislist);
                       item_product_lightbox.find(".wrapper_description").addClass("inactiv");

                        item_product_lightbox.find(".disabled_whislist, .bloc_add_color")
                            .on('mouseenter', function() {
                            // Mouse enter
                            item_product_lightbox.find(size_not_selected_txt).addClass("show");
                            })
                            .on('mouseleave', function() {
                            // Mouse leave
                            item_product_lightbox.find(size_not_selected_txt).removeClass("show");
                            });
                   }
               } else {
                   $(this).find(".prodSize").prop("checked",true);
               }
           });
       });

       $(".close_look_lightbox").on("click", function () {
           let this_data_id_item_product_close_cms = $(this).parent('.tag').find('.item.gondole').attr('id');

           $(this).parents('.tag').find('.item.gondole').removeClass('actif');
           $(".tag").removeClass('actif');
           closeMultiShad(this_data_id_item_product_close_cms);
       });
   }
}

function glDisplayCart()
{
    CartShow();
}

// Change the button text on the FP when size is not selected
function eventSizeButton() {
    var disabledBtnTooltip = $(".disabledBtnTooltip");

    disabledBtnTooltip.find(".disabledBtnInfos").text(Translator.translate('choose_size_fp_disabled_btn'));
}

// for each site
function extraOpenGlLightbox() {
    if ($("body").addClass("product_page")) {
        $('#shad').addClass('actif');
    }
}

function extraCloseGlLightbox() {
    $('#shad').removeClass('actif');
}

function removeWp(from)
{
    let current_elem = $(this);
    let product_id = current_elem.data("prod");
    let color_id = current_elem.data("color");
    let size_id = current_elem.data("size");

    let datas = {
        remove: 1,
        product_id: product_id,
        color_id: color_id,
        size_id: size_id
    }

    $.post(path_relative_root + 'ajax_remove_from_wishlist.php', datas, function (data) {

        if (typeof from !== "undefined" && from == "wishlist") {
            location.reload();
        } else {
            let nr;
            if (data.result == undefined) {
                data.result = $('.wish_liste_product .product_ctn').length;

            } else {
                nr = data.result.wishlistLength;
            }

            if (nr == 0) {
                $("#wishlist_top").removeClass('hasItem');
                $(".item.wishlist").hide();
            }

            // Update products in rayon
            if ($('a[data-productid = "' + product_id + '"]').length > 0) {
                $('a[data-productid = "' + product_id + '"]').removeClass("existToWishlistButton");
                $('a[data-productid = "' + product_id + '"]').data('wishlistproductid', '');
                $('a[data-productid = "' + product_id + '"]').attr('data-wishlistproductid', '');
            }

            let response_html = ajax_file(path_relative_root + 'ajax_show_wishlist.php');
            $("#wishlist_top").html(response_html);

            $(current_elem.parents('.product-link')).fadeOut(100, function () {

                current_elem.parents('.product-link').remove();
            });
        }
    });
}

function mySubmit(pType) {
    let form = document.getElementById("formPanier");
    form.action = create_link('order_basket');
    setTimeout(function () {
        form.submit();
    });
}

function headerContainer_sticky() {
    let scroll = $(window).scrollTop();

    if (scroll >= $(".headerContainer").height()) {
        if (!isStickyApplied) {
            setTimeout(function () {
                $(".headerContainer").addClass("sticky");
            }, 300);
            isStickyApplied = true;
        }
    } else {
        if (isStickyApplied) {
            setTimeout(function () {
                $(".headerContainer").removeClass("scroll sticky");
            }, 0);
            isStickyApplied = false;
        }
    }
}

// Check if color/size are selected before enabling add to cart button for product sheet
function checkSubmitStateProduct() {
    "use strict";

    let checkColor = $('.product_info .prodColor');
    let checkSize = $('.product_info .prodSize');

    let iscolorChecked = checkColor.is("input[type=radio]") ? checkColor.is(':checked') : checkColor.val();
    let issizeChecked = checkSize.is("input[type=radio]") ? checkSize.is(':checked') : checkSize.val();

    if ($('.product_info #couleurProd').val() == 'none') {
        iscolorChecked = true;
    }
    if ($('.product_info #tailleProd').val() == 'none') {
        issizeChecked = true;
    }

    let disabledBtnTooltip = $(".product_main_wrapper .disabledBtnTooltip, .pos_sticky .disabledBtnTooltip");
    let disabledBtnInfos = $(".product_main_wrapper .disabledBtnInfos, .pos_sticky .disabledBtnInfos");
    let productSizeFieldset = $(".product_main_wrapper .productSizeFieldset");
    let productColorFieldset = $(".product_main_wrapper .productColorFieldset");

    if (!checkColor.length && !checkSize.length) {
        enableAddCart();
    } else if (checkColor.length && checkSize.length) {
        if (!iscolorChecked && !issizeChecked) {
            disableAddCart();
            productSizeFieldset.append(disabledBtnTooltip);
            disabledBtnInfos.text(Translator.translate('choose_a_size_and_a_color'));
        } else if (iscolorChecked && !issizeChecked) {
            disableAddCart();
            productSizeFieldset.append(disabledBtnTooltip);
            disabledBtnInfos.text(Translator.translate('choose_a_size'));
        } else if (!iscolorChecked && issizeChecked) {
            disableAddCart();
            productColorFieldset.append(disabledBtnTooltip);
            disabledBtnInfos.text(Translator.translate('choose_a_color'));
        } else {
            enableAddCart();
        }
    } else if (checkColor.length) {
        if (!iscolorChecked) {
            disableAddCart();
            productColorFieldset.append(disabledBtnTooltip);
            disabledBtnInfos.text(Translator.translate('choose_a_color'));
        } else {
            enableAddCart();
        }
    } else if (checkSize.length) {
        if (!issizeChecked) {
            disableAddCart();
            productSizeFieldset.append(disabledBtnTooltip);
            disabledBtnInfos.text(Translator.translate('choose_a_size'));
        } else {
            enableAddCart();
        }
    }
}

function deleteItemFromCart (id, qty) {
    if (typeof dataLayer_v3 !== 'undefined' && typeof dl_v3_impressions !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && id !== null && id !== undefined) {
        DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, id, "remove_from_cart", qty);
    }
}

// Offer selection for products with multiple sizes
function selectionOffertSize(div_id, form_id) {
    event.preventDefault();
    event.stopPropagation();
    let form = $("#" + form_id);
    let $div_colorlist = form.find('.color_list');
    let errorLabel = $('#errorLabel');

    if (prev_form == null){
        prev_form = form;
        prev_div_colorlist = $div_colorlist;
    }
    // Refresh feature of the display of the selected product if there is only one offered product for the CP
    if (nb_selection_offert_selected + nb_selection_offert_restant == 1 && $('.js-form-selection-offer.actif').length == 1 && !form.hasClass('actif')) {

        let previous_form_actif = $('.js-form-selection-offer.actif');
        removeProdSelection(previous_form_actif, previous_form_actif.find('.color_list'));
    }

    // We deactivate those that are inactive
    let className = form.attr('class');
    // Check the number of selected products
    let actifElt = $('.' + className + ' .actif');
    // Quantity of the product
    let qty = form.find('input[name="product_qty"]').val();

    // When clicking on a selected product to remove it
    if (nb_selection_offert_restant == 0 && qty == 1 && form.hasClass('actif')) {

        removeProdSelection(form, $div_colorlist);
    } else {

        if (!form.hasClass('actif')) { // Product selection

            errorLabel.html(''); // Remove the error message when a product is selected
            $("#" + div_id).prop('checked', true);

            if (actifElt.length < nb_selection_offert_restant) {

                form.addClass('actif');
                nb_selection_offert_selected++;
                nb_selection_offert_restant--;
                form.find('input[name="product_qty"]').val(1);
                if ($div_colorlist.length > 0) {

                    $div_colorlist.addClass('open');
                }
                prev_form = form;
                prev_div_colorlist = $div_colorlist;
            } else if (nb_selection_offert_restant == 0 && $('.js-form-selection-offer.actif').length > 0) {
                removeProdSelection(prev_form, $div_colorlist);

                if (actifElt.length < nb_selection_offert_restant) {
                    form.addClass('actif');
                    nb_selection_offert_selected++;
                    nb_selection_offert_restant--;
                    form.find('input[name="product_qty"]').val(1);
                    if ($div_colorlist.length > 0) {

                        $div_colorlist.addClass('open');
                    }
                    prev_form = form;
                    prev_div_colorlist = $div_colorlist;
                }
            }

            if (nb_selection_offert_restant == 0) {
                $('.selection-offer-submit').removeClass('disabled');
            }
        } else { // Remove du prod sélectionné
            removeProdSelection(form, $div_colorlist);
        }
    }
    // nb_prod_selection_offerte in the translation
    $('#nb_prod_selection_offerte').html(nb_selection_offert_restant);
}

// Function related to the selectionOffert function, allows to remove a selected offered product from a form
function removeProdSelection(form, $div_colorlist) {

    form.removeClass('actif');
    nb_selection_offert_selected--;
    nb_selection_offert_restant++;
    form.find('input[name="product_qty"]').val(0);
    form.find('.prodSize').prop('checked', false);

    if ($div_colorlist.length > 0) {

        $div_colorlist.removeClass('open');
    }
    $('.selection-offer-submit').addClass('disabled');
}

function wording_faq_cart() {
    if ($("body.cart .wrap_bottom_rightcol .bloc_question").length && $('.questions_frequentes_title').length === 0) {
        $('.wrap_bottom_rightcol .bloc_question .faq_theme_wrapper:first-of-type').prepend('<p class="questions_frequentes_title">' + Translator.translate('questions_frequentes_title') + '</p>');
    }
}

// Disable add to cart btn and display a rollover text information.
function disableAddCart() {
    "use strict";

    let addCartWrapper = $(".btnAddBasketWrapper");
    let addCartBtn = $(".btnAddBasket");
    let disabledBtnInfos = $(".disabledBtnInfos");
    let disabledBtnTooltip = $(".disabledBtnTooltip");
    let ctaAddToGiftList = $("#ctaAddToGiftList");

    addCartWrapper.addClass("inactiv");
    addCartWrapper.removeClass("active_btn");
    ctaAddToGiftList.addClass("btnAddBasketWrapper inactiv");
    ctaAddToGiftList.removeClass("active_btn");
    addCartBtn.prop("disabled", true);
    disabledBtnTooltip.addClass("actif");
    disabledBtnTooltip.removeClass("blink");
    disabledBtnInfos.removeClass("cache");
}

// Enable add to cart btn
function enableAddCart() {
    "use strict";

    let addCartWrapper = $(".btnAddBasketWrapper");
    let addCartBtn = $(".btnAddBasket");
    let disabledBtnInfos = $(".disabledBtnInfos");
    let disabledBtnTooltip = $(".disabledBtnTooltip");
    let ctaAddToGiftList =$("#ctaAddToGiftList");

    disabledBtnInfos.text("");
    addCartWrapper.removeClass("inactiv");
    addCartWrapper.addClass("active_btn");
    ctaAddToGiftList.removeClass("inactiv");
    ctaAddToGiftList.addClass("active_btn");
    addCartBtn.prop("disabled", false);
    disabledBtnTooltip.removeClass("actif");
    disabledBtnTooltip.addClass("blink");
    disabledBtnInfos.addClass("cache");
}

function hideModalAction(evt)
{
    evt.preventDefault();
    $(evt.target).parents('.product_line_basket').find('.cart_item_actions').slideUp('fast');
    $(evt.target).parents('.product_line_basket').find('.toggle_modal_actions').fadeIn('slow');
}

// Swiper in header supercategories hover
function initCategSwiper(elem, update = false) {
    if(!$(elem).hasClass('swiper-initialized')) {
        new Swiper(elem, {
            loop: false,
            slidesPerView: 'auto',
            spaceBetween: 20,
            watchOverflow: true,
            navigation: {
                nextEl: ".menu_button .swiper-button-next",
                prevEl: ".menu_button .swiper-button-prev",
            },
            breakpoints: {
                1024: {
                    spaceBetween: 30,
                },
            },
        });
    }
}

function processPushBasket() {
    $('.push_container.pushBasketWrapper').before($('.fidelity_program_bloc'));
        $('.fidelity_program_bloc').before($('.wrap_bottom_rightcol'));
        $('.fidelity_program_bloc, .pushBasketWrapper').fadeIn();
}


/**
 * GET dataLayer object data for PINTERST EVENTS
 * @param {string} type
 * @param {string} id
 * @param {string} currency
 */
function getDataLayerObjectInfo(type, id, currency) {
    var impressions = data_layer_products;
    let data = {
        type: type,
        id: id,
        json: 'json'
    };


    var dataInfo = {
        event: "productImpressions",
        ecommerce: {
            currencyCode: currency,
            impressions: [impressions]
        }
    }

    $.ajax({
        url: path_relative_root + 'ajax_object_data.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {
            // Set event ID for product detail dataLayer
            if (typeof (res) == 'object') {
                dataInfo['ecommerce']['event_id'] = res.dataLayerData.event_id;
                pushIntoDatalayer(dataInfo);
            }
        }
    });
}
